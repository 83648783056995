import React, { useState, useEffect } from 'react';
import Billing from './Billing';
import Profile from './Profile';
import Password from './Password';
import { removeCard } from '../../actions/ProfileAction';
import { parse } from 'query-string';
import { toast } from 'react-toastify';

function ProfileArea({
  getProfile,
  saveProfile,
  profile,
  updating,
  getCountries,
  loadingCountries,
  countries,
  getStates,
  loadingStates,
  states,
  addCard,
  removeCard,
  setDefaultCard,
  addCardLoading,
  addCardUrl,
  cardLoading,
  newDefault,
  removedCard,
  history,
}) {
  const [currentTab, setCurrentTab] = useState('profile');

  useEffect(() => {
    getProfile();
    const urlParams = parse(history.location.search);
    if (urlParams.action && urlParams.action.trim() === 'add_card') {
      setCurrentTab('billing');
      if (urlParams.failed && urlParams.failed.toString().trim() === 'true') {
        toast.error('Failed to add card.');
      } else {
        toast.success('Added card successfully.');
      }
    }
  }, []);

  return (
    <>
      <div className="WpdTabWrapper">
        <div className="WpdTab">
          <ul className="WpdTabNav">
            <li className="WpdTabNavItem">
              <button
                onClick={() => setCurrentTab('profile')}
                className={`WpdTabNavItemLink ${
                  currentTab === 'profile' && 'Active'
                }`}
              >
                Profile
              </button>
            </li>
            <li className="WpdTabNavItem">
              <button
                onClick={() => setCurrentTab('billing')}
                className={`WpdTabNavItemLink ${
                  currentTab === 'billing' && 'Active'
                }`}
              >
                Billing & Payment Methods
              </button>
            </li>
            <li className="WpdTabNavItem">
              <button
                onClick={() => setCurrentTab('password')}
                className={`WpdTabNavItemLink ${
                  currentTab === 'password' && 'Active'
                }`}
              >
                Password
              </button>
            </li>
          </ul>
        </div>
        {currentTab === 'profile' ? (
          <Profile
            saveProfile={saveProfile}
            profile={profile}
            updating={updating}
          />
        ) : currentTab === 'billing' ? (
          <Billing
            saveProfile={saveProfile}
            profile={profile}
            updating={updating}
            getCountries={getCountries}
            loadingCountries={loadingCountries}
            countries={countries}
            getStates={getStates}
            loadingStates={loadingStates}
            states={states}
            addCard={addCard}
            removeCard={removeCard}
            setDefaultCard={setDefaultCard}
            addCardLoading={addCardLoading}
            cardLoading={cardLoading}
            addCardUrl={addCardUrl}
            newDefault={newDefault}
            removedCard={removedCard}
          />
        ) : currentTab === 'password' ? (
          <Password saveProfile={saveProfile} updating={updating} />
        ) : null}
      </div>
    </>
  );
}

export default ProfileArea;
