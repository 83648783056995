import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import LoadingButton from '../../utils/LoadingButton';
import { downloadItem, validateFormFields } from '../../utils/helper';

const GenerateInvoice = ({
  getInvoiceData,
  invoiceData,
  loading,
  getCountries,
  loadingCountries,
  countries,
  getStates,
  loadingStates,
  states,
  generateCustomInvoice,
  generated,
  match,
  resetGenerated,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(undefined);
  const [disabledState, setDisabledState] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [hasSavedCountry, setHasSavedCountry] = useState(undefined);
  const [hasSavedState, setHasSavedState] = useState(undefined);
  const [formFields, setFormFields] = useState({
    city: '',
    country: '',
    line1: '',
    line2: '',
    name: '',
    notes: '',
    state: '',
    zip: '',
    email: '',
    vat: '',
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    match.params.paymentId &&
      getInvoiceData({
        payment_id: match.params.paymentId,
      });
  }, []);

  useEffect(() => {
    if (countries) {
      setCountryOptions(countries.filter((c) => c.value.length));
      if (hasSavedCountry) {
        const saved = countries.filter(
          (c) => c.value.toString() === hasSavedCountry
        );
        setSelectedCountry(saved);
      }
    }
  }, [countries]);

  useEffect(() => {
    if (states && states.length) {
      setDisabledState(false);
      setStateOptions(states.filter((s) => s.value.length));
      if (hasSavedState) {
        const saved = states.filter(
          (s) => s.value.toString() === hasSavedState
        );
        setSelectedState(saved);
      } else {
        setSelectedState(undefined);
      }
    }
  }, [states]);

  useEffect(() => {
    if (invoiceData) {
      const { country, state, ...rest } = invoiceData;
      if (country) {
        loadCountries();
        setHasSavedCountry(country);
        if (state) {
          getStates(country);
          setHasSavedState(state);
        }
      }
      setFormFields({
        ...formFields,
        country,
        state,
        ...rest,
      });
    }
  }, [invoiceData]);

  useEffect(() => {
    if (generated) {
      downloadItem(`Invoice-${invoiceData.number}.pdf`, generated);
      resetGenerated();
    }
  }, [generated]);

  const loadCountries = () => {
    !countries && getCountries();
  };

  const handleCountryChange = (selected) => {
    if (selected && selected.value?.length) {
      setSelectedState({});
      getStates(selected.value);
      setSelectedCountry(selected);
      setFormFields({
        ...formFields,
        country: selected.value,
        state: '',
      });
    } else {
      setFormFields({
        ...formFields,
        country: '',
        state: '',
      });
      setSelectedCountry(undefined);
    }
    setFormErrors({ ...formErrors, state: '' });
    setDisabledState(true);
    setStateOptions([]);
  };

  const handleStateChange = (selected) => {
    if (selected && selected.value?.length) {
      setSelectedState(selected);
      setFormFields({ ...formFields, state: selected.value });
    } else {
      setFormFields({ ...formFields, state: '' });
      setSelectedState(undefined);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validated = validateFormFields(formFields, getValidationRules());
    if (validated.valid) {
      generateCustomInvoice({
        ...formFields,
        payment_id: match.params.paymentId,
      });
    } else {
      setFormErrors({ ...validated.errors });
    }
  };

  const getValidationRules = () => {
    const rules = {
      name: {
        required: {
          value: true,
          message: 'Please insert billing name',
        },
        maxLength: {
          value: 100,
          message: 'Billing name should not be more than 100 characters',
        },
      },
      email: {
        required: {
          value: true,
          message: 'Please insert email',
        },
        email: {
          value: true,
          message: 'Please enter a valid email',
        },
      },
      country: {
        required: true,
      },
      line1: {
        required: {
          value: true,
          message: 'Please insert billing address',
        },
        maxLength: {
          value: 255,
          message: 'Billing address should not be more than 255 characters',
        },
      },
      line2: {
        maxLength: {
          value: 255,
          message:
            'Billing address line 2 should not be more than 255 characters',
        },
      },
      city: {
        required: {
          value: true,
          message: 'Please insert city',
        },
        maxLength: {
          value: 60,
          message: 'City should not be more than 60 characters',
        },
      },
      zip: {
        required: {
          value: true,
          message: 'Please insert zip/postal code',
        },
        maxLength: {
          value: 24,
          message: 'Zip/postal code should not be more than 24 characters',
        },
      },
    };
    if (stateOptions.length) {
      rules.state = {
        required: true,
      };
    }
    return rules;
  };

  const showError = (name) => {
    if (formErrors.hasOwnProperty(name) && formErrors[name].length) {
      return <p className="WpdErrorMessage">{formErrors[name]}</p>;
    }
    return '';
  };

  return (
    <section>
      <form className={`WpdFormWrapper`} onSubmit={handleSubmit}>
        <div className="WpdSectionTitleWrap">
          <Link to={'/user/purchase-history'} className="WpdBackButton">
            <span className="WpdIcon">
              <i className="wpd-icon wpd-angle-left"></i>
            </span>
          </Link>
          <h4 className="WpdSectionTitle mr-2">Generate Invoice for payment</h4>
        </div>
        <div className={`row ${loading ? 'is-updating' : ''}`}>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Billing Name</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setFormFields({ ...formFields, name: e.target.value })
                }
                value={formFields.name}
              />
              {showError('name')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Billing email</label>
              <input
                type="email"
                className="form-control"
                onChange={(e) =>
                  setFormFields({ ...formFields, email: e.target.value })
                }
                value={formFields.email}
              />
              {showError('email')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Billing Address Line 1</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setFormFields({ ...formFields, line1: e.target.value })
                }
                value={formFields.line1}
              />
              {showError('line1')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Billing Address Line 2</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setFormFields({ ...formFields, line2: e.target.value })
                }
                value={formFields.line2}
              />
              {showError('line2')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Country</label>
              <Select
                options={countryOptions}
                onMenuOpen={loadCountries}
                isLoading={loadingCountries}
                onChange={handleCountryChange}
                isSearchable={true}
                isClearable={true}
                classNamePrefix="WpdSelect"
                value={selectedCountry}
              />
              {showError('country')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">State / Province</label>
              <Select
                options={stateOptions}
                isLoading={loadingStates}
                onChange={handleStateChange}
                value={selectedState}
                isSearchable={true}
                isClearable={true}
                isDisabled={disabledState}
                classNamePrefix="WpdSelect"
              />
              {showError('state')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">City</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setFormFields({ ...formFields, city: e.target.value })
                }
                value={formFields.city}
              />
              {showError('city')}
            </div>
          </div>
          <div className="col-md-6">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Zip / Postal Code</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setFormFields({ ...formFields, zip: e.target.value })
                }
                value={formFields.zip}
              />
              {showError('zip')}
            </div>
          </div>
          <div className="col-12">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Tax / Vat number</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setFormFields({ ...formFields, vat: e.target.value })
                }
                value={formFields.vat}
              />
              {showError('vat')}
            </div>
          </div>
          <div className="col-md-12">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Custom Notes</label>
              <textarea
                className="form-control"
                rows="3"
                onChange={(e) =>
                  setFormFields({ ...formFields, notes: e.target.value })
                }
                value={formFields.notes}
              ></textarea>
              {showError('notes')}
            </div>
          </div>
        </div>
        <button className="WpdButton WpdInfoButton WpdFilled mt-2">
          <span className="WpdButtonInner">
            {loading ? (
              <>
                <LoadingButton>Loading</LoadingButton>
              </>
            ) : (
              <span className="WpdText">Generate </span>
            )}
          </span>
        </button>
      </form>
    </section>
  );
};

export default GenerateInvoice;
