import React, { useEffect, useState } from 'react';
import Alston from '../../Markup/assets/img/alston.jpeg';
import Danial from '../../Markup/assets/img/danial.jpeg';
import Msmarkita from '../../Markup/assets/img/Msmarkita.jpeg';
import Rafal from '../../Markup/assets/img/Rafal.jpeg';

const Review = () => {
  const [selected, setSelected] = useState({
    name: '',
    details: '',
    image: '',
  });
  const reviews = [
    {
      name: 'Alston',
      details:
        'Great Add-on (Super Support & Backed Up by Speed Tests). This tool will help our web design business.It is such a great product because elementor is excellent but this addon offers a lot more features and functions.',
      image: Alston,
    },
    {
      name: 'Daniel',
      details:
        'This plugin ticks all the boxes. It’s effective in driving conversions. It’s slick and well designed. It’s so easy to edit and use. LOVE THIS PLUGIN.',
      image: Danial,
    },
    {
      name: 'Msmarkita',
      details:
        'Love This Platform. Love how user-friendly this site is as well as they have excellent customer service!',
      image: Msmarkita,
    },
    {
      name: 'Rafal',
      details:
        'Great support. Love plugin but even more I love its support 🙂 Great job!  I had problem with displaying post in Instant Answer widget – solved of course 🙂 ',
      image: Rafal,
    },
  ];
  useEffect(() => {
    const random = Math.floor(Math.random() * reviews.length);
    setSelected(reviews[random]);
  }, []);
  return (
    <section className="WpdRatingSection WpdSectionGap">
      <span className="WpdRattingQuoteIcon">
        <i className="wpd-icon wpd-left-quote"></i>
      </span>
      <p className="WpdReview">{selected.details}</p>
      <div className="WpdRatingWrapper">
        <span className="WpdRate WpdFilled">
          <i className="wpd-icon wpd-star"></i>
        </span>
        <span className="WpdRate WpdFilled">
          <i className="wpd-icon wpd-star"></i>
        </span>
        <span className="WpdRate WpdFilled">
          <i className="wpd-icon wpd-star"></i>
        </span>
        <span className="WpdRate WpdFilled">
          <i className="wpd-icon wpd-star"></i>
        </span>
        <span className="WpdRate WpdFilled">
          <i className="wpd-icon wpd-star"></i>
        </span>
      </div>
      <div className="WpdRatingDepositor">
        <div className="WpdRatingDepositorImage">
          <img src={selected.image} alt="" />
        </div>
        <div className="WpdRatingDepositorInfo">
          <h3 className="WpdRatingDepositorTitle">{selected.name}</h3>
        </div>
      </div>
    </section>
  );
};

export default Review;
