import { all, put, takeLatest } from 'redux-saga/effects';
import {
  ACCOUNT_SWITCH,
  ACCOUNT_SWITCH_SUCCESS,
  ACCOUNT_SWITCH_FAILED,
} from '../actions/HeaderActions';
import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

export function* attempSwitchAccount(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user/switch-account`;
  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);
  if (response) {
    const data = response.data;
    yield put({
      type: ACCOUNT_SWITCH_SUCCESS,
      message: data.message,
      allProfile: data.data.allProfiles,
      activeProfiles: data.data.activeProfile,
      loggedInAccountType: data.data.loggedInAccountType,
      role_permission: data.data.role_permission,
    });
  }
  if (error) {
    const data = error.data;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: ACCOUNT_SWITCH_FAILED,
        message: data.message,
      });
    }
  }
}

export function* switchAccount() {
  yield takeLatest(ACCOUNT_SWITCH, attempSwitchAccount);
}

export default function* rootSaga() {
  yield all([switchAccount()]);
}
