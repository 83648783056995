import { connect } from 'react-redux';
import Dashboard from '../components/Dashboard/Dashboard';
import { getProducts } from '../actions/ProductAction';
import { getLatestNews } from '../actions/NewsActions';
import { setSiteData } from '../actions/ManageSiteActions';
import { downloadProduct } from '../actions/DownloadActions';

const mapStateToProps = (state) => {
  return {
    products: state.product.products,
    productsLoading: state.product.loading,
    newsLoading: state.news.loading,
    latestNews: state.news.latest,
    signin: state.auth.signin,
    downloadItem: state.downloads.downloadItem,
    downloading: state.downloads.downloading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: (params) => dispatch(getProducts(params)),
    getLatestNews: () => dispatch(getLatestNews()),
    setSiteData: (paymentId, data) => dispatch(setSiteData(paymentId, data)),
    downloadProduct: (data) => dispatch(downloadProduct(data)),
  };
};

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
