export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILED = 'GET_TICKETS_FAILED';

export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED';

export function getTickets() {
  return {
    type: GET_TICKETS,
  };
}

export function createTicket(data) {
  return {
    type: CREATE_TICKET,
    data,
  };
}
