import { connect } from 'react-redux';
import Subscription from '../components/Subscription/Subscription';
import {
  cancelSubscription,
  getSubscriptionData,
} from '../actions/SubscriptionAction';

const mapStateToProps = (state) => {
  return {
    subscription: state.subscription.subscriptions,
    loading: state.subscription.loading,
    updating: state.subscription.updating,
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    getSubscriptionData: () => dispatch(getSubscriptionData()),
    cancelSubscription: (id, type) => dispatch(cancelSubscription(id, type)),
  };
};

const SubscriptionContainer = connect(
  mapStateToProps,
  mapDispatchToprops
)(Subscription);

export default SubscriptionContainer;
