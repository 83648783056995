import React, { useEffect, useState } from 'react';
import AffiliateUrl from './AffiliateUrl';
import Creatives from './Creatives';
import Graph from './Graphs';
import Payouts from './Payouts';
import Referral from './Referral';
import Settings from './Settings';
import Statistics from './Statistics';
import Visitors from './Visitors';
import Loader from '../../utils/Loader';
import ExistingUserRegistration from './ExistingUserRegistration';

const Affiliate = ({
  loading,
  profile,
  getProfile,
  getVisits,
  visits,
  getReferrals,
  referrals,
  getPayouts,
  payouts,
  registerNewUser,
  registrationSuccess,
  getCreatives,
  creatives,
}) => {
  const [currentTab, setCurrentTab] = useState('url');
  const [affiliateInfo, setAffiliateInfo] = useState({
    id: '',
    referral_url: '',
    referral_var: '',
    status: '',
  });
  const [showAffiliateArea, setShowAffiliateArea] = useState(undefined);

  useEffect(() => {
    if (!profile) {
      getProfile();
    } else {
      setShowAffiliateArea(profile.has_affiliate);
      if (profile.has_affiliate) {
        setAffiliateInfo(profile.affiliates);
      }
    }
  }, [profile]);

  return (
    <>
      {showAffiliateArea ? (
        <div className="WpdTabWrapper">
          <div className="WpdTab WpdFullHeight">
            <ul className="WpdTabNav">
              <li className="WpdTabNavItem">
                <button
                  onClick={() => setCurrentTab('url')}
                  className={`WpdTabNavItemLink ${
                    currentTab === 'url' && 'Active'
                  }`}
                >
                  Affiliate Url
                </button>
              </li>
              {/*<li className="WpdTabNavItem">
                <button
                  onClick={() => setCurrentTab('stat')}
                  className={`WpdTabNavItemLink ${
                    currentTab === 'stat' && 'Active'
                  }`}
                >
                  Statistic
                </button>
              </li>*/}
              {/*<li className="WpdTabNavItem">
              <button
                onClick={() => setCurrentTab('graph')}
                className={`WpdTabNavItemLink ${
                  currentTab === 'graph' && 'Active'
                }`}
              >
                Graphs
              </button>
            </li>*/}
              <li className="WpdTabNavItem">
                <button
                  onClick={() => setCurrentTab('referral')}
                  className={`WpdTabNavItemLink ${
                    currentTab === 'referral' && 'Active'
                  }`}
                >
                  Referrals
                </button>
              </li>
              <li className="WpdTabNavItem">
                <button
                  onClick={() => setCurrentTab('payout')}
                  className={`WpdTabNavItemLink ${
                    currentTab === 'payout' && 'Active'
                  }`}
                >
                  Payouts
                </button>
              </li>
              <li className="WpdTabNavItem">
                <button
                  onClick={() => setCurrentTab('visit')}
                  className={`WpdTabNavItemLink ${
                    currentTab === 'visit' && 'Active'
                  }`}
                >
                  Visits
                </button>
              </li>
              <li className="WpdTabNavItem">
                <button
                  onClick={() => setCurrentTab('creative')}
                  className={`WpdTabNavItemLink ${
                    currentTab === 'creative' && 'Active'
                  }`}
                >
                  Creatives
                </button>
              </li>
              {/*<li className="WpdTabNavItem">
                <button
                  onClick={() => setCurrentTab('setting')}
                  className={`WpdTabNavItemLink ${
                    currentTab === 'setting' && 'Active'
                  }`}
                >
                  Settings
                </button>
              </li>*/}
            </ul>
          </div>
          {currentTab === 'url' ? (
            <AffiliateUrl affiliateInfo={affiliateInfo} />
          ) : currentTab === 'graph' ? (
            <Graph />
          ) : currentTab === 'payout' ? (
            <Payouts
              loading={loading}
              affiliateInfo={affiliateInfo}
              getPayouts={getPayouts}
              payouts={payouts}
            />
          ) : currentTab === 'setting' ? (
            <Settings />
          ) : currentTab === 'stat' ? (
            <Statistics />
          ) : currentTab === 'visit' ? (
            <Visitors
              loading={loading}
              affiliateInfo={affiliateInfo}
              getVisits={getVisits}
              visits={visits}
            />
          ) : currentTab === 'creative' ? (
            <Creatives
              loading={loading}
              affiliateInfo={affiliateInfo}
              getCreatives={getCreatives}
              creatives={creatives}
            />
          ) : currentTab === 'referral' ? (
            <Referral
              loading={loading}
              affiliateInfo={affiliateInfo}
              getReferrals={getReferrals}
              referrals={referrals}
            />
          ) : null}
        </div>
      ) : typeof showAffiliateArea == 'undefined' ? (
        <Loader />
      ) : (
        <ExistingUserRegistration
          loading={loading}
          profile={profile}
          registerNewUser={registerNewUser}
          registrationSuccess={registrationSuccess}
          getProfile={getProfile}
        />
      )}
    </>
  );
};

export default Affiliate;
