import React, { useEffect, useState } from 'react';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { formatDate } from '../../utils/helper';
import Pagination from 'rc-pagination';
import localInfo from 'rc-pagination/es/locale/en_US';
const Visit = ({ url, referrer, referral_id, date }) => {
  return (
    <tr>
      <td>
        <label>URL:</label>
        <p>
          <a href={url} target="_blank" className="WpdColorInfo">
            {url}
          </a>
        </p>
      </td>
      <td>
        <label>Referring URL:</label>
        <p>{referrer ? referrer : 'Direct Traffic'}</p>
      </td>
      <td>
        <label>Converted:</label>
        <p className="d-flex justify-content-center text-center">
          <span
            className={`WpdIconButton  ${
              referral_id ? 'WpdSuccessButton' : 'WpdWarningButton'
            }`}
          >
            <span
              className={`wpd-icon ${referral_id ? 'wpd-tick' : 'wpd-cancel'}`}
            />
          </span>
        </p>
      </td>
      <td>
        <label>Date:</label>
        <p className="text-nowrap">{formatDate(date, false, true)}</p>
      </td>
    </tr>
  );
};

const Visitors = ({ loading, affiliateInfo, getVisits, visits }) => {
  const perPage = 20;
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (affiliateInfo) {
      getVisits({
        affiliate_id: affiliateInfo.id,
        number: perPage,
        offset: 0,
      });
    }
  }, [affiliateInfo]);
  const handlePageChange = function (page) {
    setPage(page);
    getVisits({
      affiliate_id: affiliateInfo.id,
      number: perPage,
      offset: (page - 1) * perPage,
    });
  };

  return (
    <div className="WpdTabContent">
      <section className="WpdMyStatisticsSection">
        <div className="WpdSectionTitleWrap">
          <h4 className="WpdSectionTitle mr-2">Visits</h4>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {visits && visits.data && visits.data.length ? (
              <>
                <div className="WpdDataTableWrapper">
                  <table className="WpdDataTable">
                    <thead>
                      <tr>
                        <th>URL</th>
                        <th>Referring URL</th>
                        <th>
                          <span className="d-flex justify-content-center text-center">
                            Converted
                          </span>
                        </th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {visits.data.map((visit, index) => (
                        <Visit key={index} {...visit} />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="WpdPaginationWrapper">
                  <Pagination
                    defaultCurrent={1}
                    pageSize={perPage}
                    onChange={handlePageChange}
                    total={visits.total}
                    prevIcon={'<'}
                    nextIcon={'>'}
                    locale={localInfo}
                    current={page}
                  />
                </div>
              </>
            ) : (
              <EmptyMessage>No visits found</EmptyMessage>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default Visitors;
