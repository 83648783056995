import Loader from '../../utils/Loader';
import { formatDate, twoDecimalFraction, ucFirst } from '../../utils/helper';
import EmptyMessage from '../../utils/EmptyMessage';
import React, { useEffect, useState } from 'react';

const Orders = ({ loading, orders, getOrders }) => {
  const [search, setSearch] = useState('');
  const [searched, setSearched] = useState(false);
  useEffect(() => {
    getOrders();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearched(true);
    getOrders(search);
  };

  const clearSearch = (e) => {
    e.preventDefault();
    setSearch('');
    searched && getOrders();
    setSearched(false);
  };
  return (
    <section>
      <div className="WpdSectionTitleWrap d-flex justify-content-between">
        <h4 className="WpdSectionTitle mr-2">All orders</h4>
        <div className={`WpdSearch d-flex`}>
          <input
            type="text"
            name="search"
            className="form-control"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={'Search order...'}
          />
          {(search.length && (
            <button className="WpdSearchClear" onClick={clearSearch}>
              <span className="wpd-icon wpd-cancel"></span>
            </button>
          )) ||
            ''}
          <button className="WpdSearchButton" onClick={handleSearch}>
            <span className="WpdIcon">
              <i className="wpd-icon wpd-search"></i>
            </span>
          </button>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : orders && orders.length > 0 ? (
        <>
          <div className={`WpdDataTableWrapper`}>
            <table className="WpdDataTable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Gateway</th>
                  <th>Products</th>
                  <th>Transaction Id</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td>{order.ID}</td>
                      <td>{order.email}</td>
                      <td>{order.gateway}</td>
                      <td>
                        {(order.products &&
                          order.products.length &&
                          (order.products.length > 1 ? (
                            order.products.map((product) => {
                              return (
                                <h6 className={'WpdOrderList'}>
                                  {product.name}
                                  <br />
                                  <small>{product.price_name}</small>
                                </h6>
                              );
                            })
                          ) : (
                            <h6>
                              {order.products[0].name}
                              <br />
                              <small>{order.products[0].price_name}</small>
                            </h6>
                          ))) ||
                          'No products found'}
                      </td>
                      <td>{order.transaction_id}</td>
                      <td>${twoDecimalFraction(order.total)}</td>
                      <td>{formatDate(order.date, false, true)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <EmptyMessage>No orders found</EmptyMessage>
      )}
    </section>
  );
};

export default Orders;
