export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_FAILED = 'SAVE_PROFILE_FAILED';

export const ADD_CARD = 'ADD_CARD';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_FAILED = 'ADD_CARD_FAILED';

export const SET_DEFAULT_CARD = 'SET_DEFAULT_CARD';
export const SET_DEFAULT_CARD_SUCCESS = 'SET_DEFAULT_CARD_SUCCESS';
export const SET_DEFAULT_CARD_FAILED = 'SET_DEFAULT_CARD_FAILED';

export const REMOVE_CARD = 'REMOVE_CARD';
export const REMOVE_CARD_SUCCESS = 'REMOVE_CARD_SUCCESS';
export const REMOVE_CARD_FAILED = 'REMOVE_CARD_FAILED';

export function getProfile() {
  return {
    type: GET_PROFILE,
  };
}
export function saveProfile(args) {
  return {
    type: SAVE_PROFILE,
    data: args,
  };
}
export function addCard(paymentMethod) {
  return {
    type: ADD_CARD,
    payment_method: paymentMethod,
  };
}
export function setDefaultCard(paymentMethod) {
  return {
    type: SET_DEFAULT_CARD,
    payment_method: paymentMethod,
  };
}
export function removeCard(paymentMethod) {
  return {
    type: REMOVE_CARD,
    payment_method: paymentMethod,
  };
}
