import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from '../utils/axiosApi';
import {
  loadUserListFailed,
  loadUserListSuccess,
  LOAD_USERS_LIST,
  SWITCHED_TO_USER,
  SWITCHED_TO_USER_FAILED,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILED,
  GET_ORDERS,
} from '../actions/ShopManagerActions';
import { SIGN_IN_SUCCESS } from '../actions/SigninActions';

export function* loadUsers({ params }) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/users`;
  const { response, error } = yield defaultApi(
    endpoint,
    'GET',
    {
      ...params,
      order: 'des',
      orderby: 'date',
    },
    true,
    false,
    {}
  );
  if (response) {
    yield put(loadUserListSuccess(response));
  }
  if (error) {
    yield put(loadUserListFailed(error));
  }
}

export function* switchedTo(args) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/user-switch`;

  const { type, ...data } = args;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    data,
    true,
    false,
    {}
  );

  if (response) {
    yield put({
      type: SIGN_IN_SUCCESS,
      credentials: response,
    });
  } else {
    yield put({
      type: SWITCHED_TO_USER_FAILED,
      error,
    });
  }
}

export function* getUsers() {
  yield takeLatest(LOAD_USERS_LIST, loadUsers);
  yield takeLatest(SWITCHED_TO_USER, switchedTo);
}

export function* getOrders({ search }) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/orders`;

  const { response, error } = yield defaultApi(
    endpoint,
    'GET',
    { search },
    true
  );

  if (response) {
    yield put({
      type: GET_ORDERS_SUCCESS,
      response,
    });
  } else {
    yield put({
      type: GET_ORDERS_FAILED,
      error,
    });
  }
}

export function* orders() {
  yield takeLatest(GET_ORDERS, getOrders);
}

export default function* rootSaga() {
  yield all([getUsers(), orders()]);
}
