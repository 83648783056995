import React, { useState, useEffect } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import ErrorMessage from '../../utils/ErrorMessage';

import WpiLogo from '../../Markup/assets/img/WpdLogoWithText.svg';
import Banner from '../../Markup/assets/img/login.svg';
import { toast } from 'react-toastify';

/* eslint-disable */

const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
);
/* eslint-enable */

const ResetPassword = (props) => {
  const linkHistory = useHistory();
  const goToPreviousPath = () => {
    linkHistory.goBack();
  };

  let password;
  let confirmPassword;

  const [err, setErr] = useState({
    password: [],
    confirmPassword: [],
  });

  const [additionalFields, setAdditionalFields] = useState({
    key: '',
    user: '',
    action: 'rp',
  });

  /* eslint-disable */

  useEffect(() => {
    if (props.resetPass && props.resetPass.redirect) {
      confirmPassword.value = '';
      password.value = '';
      props.history.push('/');
    }
  }, [props.resetPass]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    setAdditionalFields({
      ...additionalFields,
      key: urlSearchParams.get('key'),
      user: urlSearchParams.get('user'),
    });
  }, []);

  useEffect(() => {
    if (props.validationError.length > 0) {
      let passwordErr = [];
      let confirmPasswordErr = [];

      props.validationError.map((err) => {
        if (err.param === 'password') {
          passwordErr.push(err.msg);
        }
        if (err.param === 'password_confirmation') {
          confirmPasswordErr.push(err.msg);
        }
      });

      setErr({
        ...err,
        password: passwordErr,
        confirmPassword: confirmPasswordErr,
      });
    }
  }, [props.validationError]);
  /* eslint-enable */

  const validateForm = () => {
    const errors = [];
    let passwordErr = [];
    let confirmPasswordErr = [];

    if (password.value === '') {
      passwordErr.push("Password can't be empty");
      errors.push("Persion number can't be empty");
    }

    if (password.value.length > 0 && !strongPassRegex.test(password.value)) {
      errors.push(
        'Password must contains these: at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character,' +
          'at least 1 numeric character, at least one special character, must be eight characters or longer'
      );

      passwordErr.push(
        'Password must contain 1 lowercase, 1 uppercase,1 number and it must be 8 characters or longer'
      );
    }

    if (confirmPassword.value === '') {
      confirmPasswordErr.push("Confirm password can't be empty ");
      errors.push("Product home url can't be empty");
    }

    if (password.value !== confirmPassword.value) {
      confirmPasswordErr.push(
        'Confirm password does not match with the password'
      );
      errors.push('Confirm password does not match with the password');
    }

    if (errors.length > 0) {
      setErr({
        password: passwordErr,
        confirmPassword: confirmPasswordErr,
      });
    }

    return errors;
  };

  const submitResetPass = (e) => {
    e.preventDefault();
    var errors = validateForm();
    if (errors.length > 0) {
      return;
    }
    props.userResetPass({
      ...additionalFields,
      new_pass: password.value,
      new_pass_2: confirmPassword.value,
    });
  };

  return (
    <div className="WpdPageBody WpdLoginBody">
      <div className="WpdLoginHeader">
        <Link to={'/auth/sign-in'} className={`WpdSiteLogo`}>
          <img src={WpiLogo} alt="" />
        </Link>
      </div>
      <div className="WpdLoginWrapper">
        <div className="WpdLoginContent">
          <div className="WpdLoginForm">
            <form onSubmit={submitResetPass}>
              <Link to="/auth/sign-in" className="WpdBackButton mb-4">
                <div className="WpdIcon">
                  <i className="wpd-icon wpd-angle-left"></i>
                </div>
                <div className="WpdText">Back to Login</div>
              </Link>
              <h2>Reset Password</h2>
              <div className="WpdFormGroup mb-4">
                <label className="WpdFormLabel">New Password</label>
                <input
                  type="password"
                  className={`form-control ${
                    err.password && err.password.length && 'WpdHasError'
                  }`}
                  placeholder="********"
                  autoFocus={true}
                  ref={(node) => {
                    password = node;
                  }}
                />

                {err.password &&
                  err.password.map((err, index) => (
                    <ErrorMessage error={err} />
                  ))}
              </div>
              <div className="WpdFormGroup mb-4">
                <label className="WpdFormLabel">Confirm New Password</label>
                <input
                  type="password"
                  className={`form-control ${
                    err.confirmPassword &&
                    err.confirmPassword.length &&
                    'WpdHasError'
                  }`}
                  placeholder="********"
                  ref={(node) => {
                    confirmPassword = node;
                  }}
                />
                {err && err.confirmPassword && (
                  <ErrorMessage error={err.confirmPassword} />
                )}
              </div>
              <button
                type="submit"
                className="WpdButton WpdInfoButton WpdFilled w-100 mt-2"
              >
                <span className="WpdButtonInner">
                  <span className="WpdText">Submit</span>
                </span>
              </button>
            </form>
          </div>
        </div>
        <div className="WpdLoginImage">
          <img src={Banner} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
