import { all, put, takeLatest } from 'redux-saga/effects';
import {
  GET_SUBSCRIPTION_DATA,
  GET_SUBSCRIPTION_DATA_FAILED,
  GET_SUBSCRIPTION_DATA_SUCCESS,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from '../actions/SubscriptionAction';
import { defaultApi } from '../utils/axiosApi';

export function* loadSubscriptionsData() {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/subscriptions`;

  const { response, error } = yield defaultApi(
    endpoint,
    'GET',
    undefined,
    true
  );
  if (response) {
    yield put({
      type: GET_SUBSCRIPTION_DATA_SUCCESS,
      response,
    });
  }
  if (error) {
    yield put({
      type: GET_SUBSCRIPTION_DATA_FAILED,
      error,
    });
  }
}

export function* loadSubscriptions() {
  yield takeLatest(GET_SUBSCRIPTION_DATA, loadSubscriptionsData);
}

export function* updateSubscription({ id, updateType }) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/subscriptions`;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    { id, type: updateType },
    true
  );
  if (response) {
    yield put({
      type: UPDATE_SUBSCRIPTION_SUCCESS,
      response,
      updateType,
    });
  }
  if (error) {
    yield put({
      type: UPDATE_SUBSCRIPTION_FAILED,
      error,
      updateType,
    });
  }
}
export function* update() {
  yield takeLatest(UPDATE_SUBSCRIPTION, updateSubscription);
}

export default function* rootSaga() {
  yield all([loadSubscriptions(), update()]);
}
