import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
  GET_LICENSE_DATA,
  GET_LICENSE_DATA_FAILED,
  GET_LICENSE_DATA_SUCCESS,
} from '../actions/ManageLicenseActions';

const initialState = {
  licenses: undefined,
  loading: false,
};

export default function manageLicenseReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LICENSE_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_LICENSE_DATA_SUCCESS:
      return {
        ...state,
        licenses: action.response,
        loading: false,
      };
    case GET_LICENSE_DATA_FAILED:
      toast.error(action.error.message);
      return {
        ...state,
        licenses: undefined,
        loading: false,
      };

    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
