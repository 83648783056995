import React from 'react';

function Settings() {
  return (
    <div className="WpdTabContent">
      <section className="WpdMyAffiliateSection">
        <form className="WpdFormWrapper">
          <div className="WpdSectionTitleWrap">
            <h4 className="WpdSectionTitle mr-2">Profile Settings</h4>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="WpdFormGroup">
                <label className="WpdFormLabel">Your Payment Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="something@email.com"
                />
              </div>
            </div>
          </div>
          <div className="WpdSectionTitleWrap">
            <h4 className="WpdSectionTitle mr-2">Notification Settings</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="WpdCheckbox">
                <input type="checkbox" />
                <span className="WpdText">
                  Enable New Referral Notifications
                </span>
              </label>
            </div>
          </div>
          <button className="WpdButton WpdInfoButton WpdFilled mt-2">
            <span className="WpdButtonInner">
              <span className="WpdText">Save Settings</span>
            </span>
          </button>
        </form>
      </section>
    </div>
  );
}

export default Settings;
