import Loader from '../../utils/Loader';
import { ucFirst } from '../../utils/helper';
import EmptyMessage from '../../utils/EmptyMessage';
import React, { useEffect, useState } from 'react';

const Users = ({
  getUserList,
  users,
  switchTo,
  userType,
  history,
  loading,
}) => {
  const perPage = 20;
  const [search, setSearch] = useState('');
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (userType === 'CUSTOMER') {
      history.push('/user/dashboard');
    }
    !users?.data?.length &&
      getUserList({
        page: 1,
        per_page: perPage,
      });
  }, []);

  useEffect(() => {
    if (userType === 'CUSTOMER') {
      window.location = '/user/dashboard';
    }
  }, [userType]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearched(true);
    getUserList({
      per_page: perPage,
      page: 1,
      search,
    });
  };
  const clearSearch = (e) => {
    e.preventDefault();
    setSearch('');
    searched &&
      getUserList({
        per_page: perPage,
        page: 1,
      });
    setSearched(false);
  };

  return (
    <section>
      <div className="WpdSectionTitleWrap d-flex justify-content-between">
        <h4 className="WpdSectionTitle mr-2">Login as User</h4>
        <div className="WpdSearch d-flex">
          <input
            type="text"
            name="search"
            className="form-control"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={'Search user...'}
          />
          {(search.length && (
            <button className="WpdSearchClear" onClick={clearSearch}>
              <span className="wpd-icon wpd-cancel"></span>
            </button>
          )) ||
            ''}
          <button className="WpdSearchButton" onClick={handleSearch}>
            <span className="WpdIcon">
              <i className="wpd-icon wpd-search"></i>
            </span>
          </button>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : users && users.data && users.data.length > 0 ? (
        <>
          <div className={`WpdDataTableWrapper`}>
            <table className="WpdDataTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.data.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{ucFirst(user.name)}</td>
                      <td>{user.email}</td>
                      <td>
                        <button
                          className={`WpdSmallButton WpdHoverInfo ml-auto`}
                          onClick={() => switchTo(user.user_id)}
                        >
                          <span className="WpdButtonInner">
                            <span className="WpdIcon">
                              <i className="wpd-icon wpd-swap" />
                            </span>
                            <span className="WpdText">Switch to user</span>
                          </span>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <EmptyMessage>No user list found</EmptyMessage>
      )}
    </section>
  );
};

export default Users;
