import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { signOut } from '../actions/index';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';
import LeftSideBar from '../components/Common/LeftSideBar';
import routes from '../routes';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import { setAutoLoggedInUser } from '../actions/SigninActions';
import { getProfile } from '../actions/ProfileAction';
import { Modal } from 'react-bootstrap';
import DownloadItem from '../components/Common/Download/MyDownloadItem';
import ProductIcon from '../utils/ProductIcon';
import Modal2FA from '../components/Auth/Modal2FA';

const UserLayout = ({
  signout,
  userSignOut,
  history,
  accountType,
  token,
  signin,
  getProfile,
  profile,
  setAutoLoggedInUser,
  switching,
  setup2fa,
  required2fa,
}) => {
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const stickyRef = useRef(isSticky);

  const getRoutes = (routes) => {
    let allRouts =
      accountType === 'SUPER_ADMIN'
        ? routes.filter((r) => r.is_admin_route === true)
        : routes.filter((r) => r.is_admin_route === false);

    let finalRoutes = allRouts.filter((route) => {
      if (route.layout.toString() === '/user') {
        if (
          accountType !== 'ADMIN' &&
          signin &&
          signin.hasOwnProperty('is_customer')
        ) {
          if (route.customer_only && !signin.is_customer) {
            return false;
          }
        }
        return true;
      }
      return false;
    });

    let checkPath =
      history &&
      history.location &&
      history.location.pathname &&
      history.location.pathname.split('/');

    let lastRouteParams = checkPath[2];
    let lastRouteLocation = history && history.location;
    if (accountType === 'SUPER_ADMIN') {
      let routePaths = finalRoutes.map((f) => f.path);
      if (routePaths.includes('/admin_user/' + lastRouteParams)) {
        if (checkPath.length > 3) {
          return finalRoutes.map((prop, key) => {
            if (prop.layout === '/user') {
              return (
                <ProtectedRoute
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            } else {
              return null;
            }
          });
        } else {
          history.push({
            ...lastRouteLocation,
            pathname: '/user/admin_user/' + lastRouteParams,
          });
        }
      } else {
        history.push('/user/admin_user/dashboard');
      }
      window.location.reload();
    } else {
      let routePaths = finalRoutes.map((f) => {
        if (f.hasOwnProperty('isDynamic') && f.isDynamic) {
          return f.path.split('/').slice(0, 2).join('/');
        } else {
          return f.path;
        }
      });
      if (routePaths.includes('/' + lastRouteParams)) {
        return finalRoutes.map((prop, key) => {
          return (
            <ProtectedRoute
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        });
      } else {
        history.push('/user/dashboard');
      }
      window.location.reload();
    }
  };

  const handleScroll = () => {
    let shouldBeSticky =
      (document.body.scrollTop || document.documentElement.scrollTop) > 300;

    if (shouldBeSticky !== stickyRef.current) {
      stickyRef.current = shouldBeSticky;
      setSticky(shouldBeSticky);
    }
  };

  useEffect(() => {
    if (signout && token) {
      userSignOut();
      history.push('/auth/sign-in');
    }
  }, [signout, history, userSignOut, token]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const token = localStorage.getItem('access_token');
    if (token) {
      if (!signin) {
        getProfile();
      }
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (profile && !signin) {
      setAutoLoggedInUser(profile);
    }
  }, [profile]);

  return (
    <div className={`WpdPageBody ${setup2fa ? 'WpdHas2fa' : ''}`}>
      {(setup2fa || required2fa) && <Modal2FA />}
      <LeftSideBar toggleSideBar={toggleSideBar} />
      <main className={`WpdContentArea ${isSticky ? 'WpdStickyHeader' : ''} `}>
        <Header
          toggleSideBar={toggleSideBar}
          setToggleSideBar={setToggleSideBar}
        />
        <div className={`WpdContentAreaBody ${switching ? 'is-loading' : ''}`}>
          <Switch>{getRoutes(routes)}</Switch>
        </div>
        <Footer />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.util.loading,
    signout: state.auth && state.auth.signout,
    accountType: state.auth && state.auth.signin && state.auth.signin.user_type,
    token: state.auth.token,
    profile:
      state.profile && state.profile.profile
        ? state.profile.profile
        : undefined,
    signin: state.auth && state.auth.signin ? state.auth.signin : undefined,
    switching: state.shopManager.switching,
    setup2fa: state.auth.setup2fa,
    required2fa: state.auth.required2fa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignOut: () => dispatch(signOut()),
    getProfile: () => dispatch(getProfile()),
    setAutoLoggedInUser: (user) => dispatch(setAutoLoggedInUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);
