import React from 'react';

function EmptyMessage({ children }) {
  return (
    <div className="WpdNoDataFound">
      <span className="WpdIcon">
        <i className="wpd-icon wpd-warning"></i>
      </span>
      <span className="WpdText">{children ? children : 'No Data Found!'}</span>
    </div>
  );
}

export default EmptyMessage;
