export const SEND_PAYMENT_INFO = ' SEND_PAYMENT_INFO';
export const SEND_PAYMENT_INFO_SUCCESS = ' SEND_PAYMENT_INFO_SUCCESS';
export const SEND_PAYMENT_INFO_FAILED = ' SEND_PAYMENT_INFO_FAILED';

export const SEND_AFFILIATE_INFO = ' SEND_AFFILIATE_INFO';
export const SEND_AFFILIATE_INFO_SUCCESS = ' SEND_AFFILIATE_INFO_SUCCESS';
export const SEND_AFFILIATE_INFO_FAILED = ' SEND_AFFILIATE_INFO_FAILED';

export function sendPaymentInfo(data) {
  return {
    type: SEND_PAYMENT_INFO,
    data,
  };
}

export function sendAffiliateInfo(info) {
  return {
    type: SEND_AFFILIATE_INFO,
    info,
  };
}
