import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { configure2fa, verify2fa } from '../../actions/SigninActions';
import { maskEmail } from '../../utils/helper';

const Modal2FA = ({
  signIn,
  configure,
  loading,
  authId,
  setup2fa,
  verify,
  has2FaError,
}) => {
  const [code, setCode] = useState('');
  const onSubmit = () => {
    if (setup2fa) {
      configure();
    } else {
      if (authId && code.length) {
        verify(authId, code);
      }
    }
  };

  return (
    <Modal show={true} centered size="lg" onHide={() => {}}>
      <div className={loading ? 'is-loading' : ''}>
        <Modal.Header>
          <Modal.Title>
            {setup2fa
              ? 'Setting Up 2FA Authentication'
              : 'Verify 2FA Authentication'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!setup2fa ? (
            <div className="wpdev-2fa-form">
              <p className="mb-3">
                Enter OTP that has been sent over your email address:{' '}
                {maskEmail(signIn?.userInfo?.email)}
              </p>
              <div className={`WpdFormGroup mt-4`}>
                <label className="WpdFormLabel">Enter code</label>
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className={`form-control ${has2FaError ? 'WpdHasError' : ''}`}
                  placeholder={'Place your Verification Code here'}
                />
                {has2FaError && (
                  <p className="WpdColorWarning mt-2">{has2FaError}</p>
                )}
              </div>
              <p className="mt-4">
                <strong>IMPORTANT:</strong> If you do not receive the email with
                the OTP, please check your Spam folder or contact{' '}
                <a
                  href="https://wpdeveloper.com/support/"
                  target="_blank"
                  className="text-primary"
                >
                  support
                </a>
                .
              </p>
            </div>
          ) : (
            <>
              <p className="mb-2">
                To complete the 2FA Authentication, you will be sent OTP over
                your email address : <strong>{signIn?.userInfo?.email}</strong>
              </p>
              <p className="mb-3">
                <strong>Note:</strong> To enhance your account security and
                provide you with peace of mind, it is mandatory to configure
                Two-Factor Authentication (2FA) immediately. Take a moment to
                set up 2FA now to enjoy a worry-free experience while using our
                products.
              </p>
              {/*<div className="WpdFormGroup mt-4">
                <input
                  type="text"
                  value={signIn?.userInfo?.email}
                  disabled
                  className="form-control"
                />
              </div>*/}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="WpdButton WpdInfoButton WpdFilled ml-auto"
            data-dismiss="modal"
            onClick={onSubmit}
          >
            <span className="WpdButtonInner">
              <span className="WpdText">
                {setup2fa ? 'Configure 2FA Now' : 'Verify Authentication'}
              </span>
            </span>
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    signIn: state.auth && state.auth.signin ? state.auth.signin : undefined,
    loading: state.auth.signInLoader,
    setup2fa: state.auth.setup2fa,
    required2fa: state.auth.required2fa,
    authId: state.auth.authId,
    has2FaError: state.auth.has2faError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configure: () => dispatch(configure2fa()),
    verify: (user_id, code) => dispatch(verify2fa(user_id, code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Modal2FA);
