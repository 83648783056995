import * as serviceWorker from './serviceWorker';
import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Markup/assets/scss/style.scss';

import { store, persistor } from './configureStore';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

const hist = createBrowserHistory();

render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={hist} context={ReactReduxContext}>
        <App />
      </ConnectedRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
