import Welcome from '../../Markup/assets/img/animated-image/Welcome .svg';
import React from 'react';

const WelcomeContent = ({ userInfo }) => {
  return (
    <section className="WpdSectionPanel WpdMessageSection">
      <div className="WpdMessageSectionLeft">
        <h1 className="WpdMessageSectionTitle">
          Howdy, {userInfo.display_name}
        </h1>
        <p className="WpdMessageSectionMessage">
          From here, you'll be able to see your purchase details, download the
          plugin, get your license key, manage your profile and payment methods.
        </p>
      </div>
      <div className="WpdMessageSectionRight">
        <object
          type="image/svg+xml"
          data={Welcome}
          aria-label="Welcome image"
        />
      </div>
    </section>
  );
};
export default WelcomeContent;
