import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  resetSiginRedirect,
  switchToAccount,
} from '../../actions/HeaderActions';
import { resetAccessRemoved, signOut } from '../../actions/index';
import routes from '../../routes';
import { clearUserLocalStorage } from '../../utils/helper';
import DefaultAvatar from '../../Markup/assets/img/bruce-mars-AndE50aaHn4-unsplash 1.png';
import { switchBack } from '../../actions/ShopManagerActions';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const user = props.user;
    const accountType = props.accountType;
    const activeProfile = props.activeProfile;
    const access_removed = props.accessRemoved;
    this.state = {
      user: user,
      showNotice: false,
      accountType: accountType,
      activeProfile: activeProfile,
      activeRoute: routes.find(
        (r) => r.layout + r.path === props.location.pathname
      ),
      is_dropDown_open: false,
      accessRemoved: access_removed,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.switchSuccess !== prevProps.switchSuccess &&
      this.props.switchSuccess
    ) {
      this.props.history.push('/user/dashboard');
      this.props.resetRedirect();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        activeRoute: routes.find(
          (r) => r.layout + r.path === this.props.location.pathname
        ),
        is_dropDown_open: false,
      });
    }
    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user,
      });
    }
    if (this.props.accountType !== prevProps.accountType) {
      this.setState({
        accountType: this.props.accountType,
      });
    }
    if (this.props.accessRemoved !== prevProps.accessRemoved) {
      this.props.resetAccessRemoved();
      this.props.history.push('/user/dashboard');
    }

    if (prevProps.switched_user !== this.props.switched_user) {
      //this.setState({ refresh: Date.now() });
      window.location = '/user/dashboard';
    }
  }

  changeAccount(info) {
    this.setState({ is_dropDown_open: false });
    this.props.switchAccount({
      company_id: info.id,
    });
  }

  signOut = () => {
    clearUserLocalStorage();
    this.props.userSignOut();
    this.props.history.push('/auth/sign-in');
  };

  toggleDropdown = (e) => {
    if (this.state.is_dropDown_open) {
      this.setState({ is_dropDown_open: false });
    } else {
      this.setState({ is_dropDown_open: true });
    }
  };

  getName = () => {
    if (this.state.user) {
      return this.state.user.display_name
        ? this.state.user.display_name
        : this.state.user.name
        ? this.state.user.name
        : this.state.user.first_name + ' ' + this.state.user.last_name;
    }
    return 'No name';
  };
  render() {
    const { toggleSideBar, setToggleSideBar } = this.props;
    return (
      <header className="WpdContentAreaHeader">
        {this.state.showNotice && (
          <div className="WpdContentAreaHeaderNotice">
            <span className="WpdIcon">
              <span className="wpd-icon wpd-warning"></span>
            </span>
            <span className="WpdText">
              We are currently updating our store. Everything might not be
              working smoothly. Thank you for your patience.
            </span>
            {/* <button className="WpdCloseButton">
              <span class="wpd-icon wpd-cancel"></span>
            </button> */}
          </div>
        )}
        <div className="WpdHeaderWrapper">
          <div className="WpdContentAreaHeaderLeft">
            <button
              onClick={() => setToggleSideBar(!toggleSideBar)}
              className="WpdSidebarToggler"
            >
              <i className="wpd-icon wpd-toggle" />
            </button>
          </div>
          <div className="WpdContentAreaHeaderRight ml-auto">
            <Dropdown
              className="dropdown WpdProfileControl "
              onClick={this.toggleDropdown}
              show={this.state.is_dropDown_open}
            >
              <Dropdown.Toggle
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="WpdProfileControlButton"
                variant="outline-light"
                id="profile-dropdown"
              >
                <div className="WpdProfileImage">
                  <img
                    src={
                      this.state.user && this.state.user.profile_image
                        ? this.state.user.profile_image
                        : this.state.user && this.state.user.gravatar
                        ? this.state.user.gravatar
                        : DefaultAvatar
                    }
                    alt=""
                  />
                </div>
                <div className="WpdProfileDetails">
                  {this.props.switched_user !== null && (
                    <p
                      style={{
                        color: 'green',
                        textAlign: 'right',
                        fontSize: 13,
                      }}
                    >
                      Your are logged in as:
                    </p>
                  )}
                  <h4 className="WpdUserName">{this.getName()}</h4>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu
                show={this.state.is_dropDown_open}
                className="dropdown-menu dropdown-menu-right WpdProfileDropdown"
              >
                <ul>
                  {this.props.switched_user != null && (
                    <li>
                      <button
                        onClick={() =>
                          this.props.switchBack(this.props.switched_user.id)
                        }
                      >
                        <span className="WpdNavigationIcon">
                          <i className="wpd-icon wpd-swap"></i>
                        </span>
                        <span className="WpdNavigationText">Switch Back</span>
                      </button>
                    </li>
                  )}
                  <li>
                    <Link to="/user/profile">
                      <span className="WpdNavigationIcon">
                        <i className="wpd-icon wpd-user"></i>
                      </span>
                      <span className="WpdNavigationText">Profile</span>
                    </Link>
                  </li>

                  {this.props.switched_user === null && (
                    <li>
                      <button onClick={this.signOut}>
                        <span className="WpdNavigationIcon">
                          <i className="wpd-icon wpd-logout"></i>
                        </span>
                        <span className="WpdNavigationText">Logout</span>
                      </button>
                    </li>
                  )}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeProfile: state.auth.signin && state.auth.signin.activeProfile,
    switchSuccess: state.auth.accountSwitchSuccess,
    user: state.auth.signin && state.auth.signin.userInfo,
    switched_user: state?.auth?.signin?.switched_user ?? null,
    accountType: state.auth.signin && state.auth.signin.accountType,
    accessRemoved: state.auth && state.auth.accessRemoved,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchAccount: (value) => dispatch(switchToAccount(value)),
    resetRedirect: () => dispatch(resetSiginRedirect()),
    userSignOut: () => dispatch(signOut()),
    resetAccessRemoved: () => dispatch(resetAccessRemoved()),
    switchBack: (id) => dispatch(switchBack(id)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
