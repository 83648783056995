import React, { useEffect, useState } from 'react';
import TooltipComponent from '../../utils/TooltipComponent';

const LicenseRenewal = ({
  addToCart,
  licenseRenewing,
  cartData,
  licenseKey,
  setLicenseKey,
}) => {
  const [showRenewal, setShowRenewal] = useState(false);
  const [isRenewaled, setIsRenewaled] = useState(false);
  useEffect(() => {
    if (licenseKey && licenseKey.length) {
      setIsRenewaled(true);
    }
  }, []);

  useEffect(() => {
    if (cartData && cartData.details && cartData.details.length) {
      const item = cartData.details.filter(
        (item) => item.item_number?.options?.is_renewal
      );
      if (item.length) {
        setLicenseKey(item[0].item_number?.options?.license_key);
        setIsRenewaled(true);
      } else {
        setIsRenewaled(false);
      }
    } else {
      setIsRenewaled(false);
    }
  }, [cartData]);

  const handleRenewal = (e) => {
    e.preventDefault();
    addToCart(
      {
        action: 'renew',
        license: licenseKey,
      },
      true
    );
    setLicenseKey('');
  };

  const handleRenewalCancel = (e) => {
    e.preventDefault();
    addToCart(
      {
        action: 'renew',
        type: 'remove',
      },
      true
    );
    setLicenseKey('');
  };

  const handleLicenseKeyToggle = (e) => {
    e.preventDefault();
    setShowRenewal(!showRenewal);
    setLicenseKey('');
  };

  return (
    <section
      className={`WpdLicenseSection WpdSectionGap 
        ${licenseRenewing ? 'is-loading' : ''}
        ${showRenewal ? 'Expanded' : ''}
        ${isRenewaled ? 'Applied' : ''}
      `}
    >
      {!isRenewaled ? (
        <>
          <h4
            className="WpdLicenseSectionTitle"
            onClick={handleLicenseKeyToggle}
          >
            Renewing a License Key?
            <TooltipComponent tooltipText={'Click to enter license key'}>
              <span className="WpdCollapserToggler">
                <i className="wpd-icon wpd-angle-down"></i>
              </span>
            </TooltipComponent>
          </h4>
          {showRenewal && (
            <div className="WpdLicenseSectionBody">
              <div className="WpdFormGroup">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your License Key"
                  value={licenseKey}
                  onChange={(e) => setLicenseKey(e.target.value)}
                />
                <button
                  className="WpdApplyCouponButton"
                  onClick={handleRenewal}
                >
                  <span className="WpdText">Apply</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="WpdLicenseAppliedScreen">
          <div className="WpdText">
            <span className="WpdHighlighted">Renewing: </span>
            <span>{licenseKey}</span>
          </div>
          <button
            className="WpdRemoveButton WpdHoverWarning"
            onClick={handleRenewalCancel}
          >
            <span className="WpdRemoveIcon">
              <i className="wpd-icon wpd-cancel" />
            </span>
          </button>
        </div>
      )}
    </section>
  );
};

export default LicenseRenewal;
