export const LOAD_DASHBOARD_REPORT = 'LOAD_DASHBOARD_REPORT';
export const RENDER_DASHBOARD_REPORT = 'RENDER_DASHBOARD_REPORT';
export const LOAD_DASHBOARD_REPORT_FAILED = 'LOAD_DASHBOARD_REPORT_FAILED';

export const LOAD_DASHBOARD_OVERVIEW_REPORT = 'LOAD_DASHBOARD_OVERVIEW_REPORT';
export const RENDER_DASHBOARD_OVERVIEW_REPORT =
  'RENDER_DASHBOARD_OVERVIEW_REPORT';
export const LOAD_DASHBOARD_OVERVIEW_REPORT_FAILED =
  'LOAD_DASHBOARD_OVERVIEW_REPORT_FAILED';

export const LOAD_DASHBOARD_REPORT_SUPER_ADMIN =
  'LOAD_DASHBOARD_REPORT_SUPER_ADMIN';

export const RENDER_DASHBOARD_REPORT_SUPER_ADMIN =
  'RENDER_DASHBOARD_REPORT_SUPER_ADMIN';
export const LOAD_DASHBOARD_REPORT_FAILED_SUPER_ADMIN =
  'LOAD_DASHBOARD_REPORT_FAILED_SUPER_ADMIN';

export const EXPORT_DATA = 'EXPORT_DATA';
export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const EXPORT_DATA_FAILED = 'EXPORT_DATA_FAILED';

export function getDashboardReport(data) {
  return {
    type: LOAD_DASHBOARD_REPORT,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function getDashboardOverViewReport(data) {
  return {
    type: LOAD_DASHBOARD_OVERVIEW_REPORT,
    credentials: data,
  };
}

export function getAllDashboardReportForSuperAdmin(data) {
  return {
    type: LOAD_DASHBOARD_REPORT_SUPER_ADMIN,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function exportListData(data) {
  return {
    type: EXPORT_DATA,
    credentials: data,
  };
}
