import { connect } from 'react-redux';
import SendForgetPassLink from '../components/Auth/SendForgetPassLink';
import {
  forgetPassLink,
  resetForgetPassword,
} from '../actions/SendForgetPassLinkAction';

const mapStateToProps = (state) => {
  return {
    forgetPassLink: state.sendForgetPassLink.forgetPassLink,
    validationError: state.sendForgetPassLink.validationError,
    loading: state.sendForgetPassLink.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userForgetPass: (email) => dispatch(forgetPassLink(email)),
    resetForgetPassword: () => dispatch(resetForgetPassword()),
  };
};

const ForgetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendForgetPassLink);

export default ForgetPasswordContainer;
