import { connect } from 'react-redux';
import Dashboard from '../../components/SuperAdmin/Dashboard/Dashboard';

import {
  getAllDashboardReportForSuperAdmin,
  exportListData,
} from '../../actions/DashboardActions';

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDashboardReportForSuperAdmin: (value) =>
      dispatch(getAllDashboardReportForSuperAdmin(value)),
    exportListData: (value) => dispatch(exportListData(value)),
  };
};

const mapStateToProps = (state) => {
  return {
    allProducts: state.dashboard.allProducts,
    siteList: state.dashboard.siteList,
    wpVersion: state.dashboard.wpVersion,
    phpVersion: state.dashboard.phpVersion,
    pluginVersion: state.dashboard.pluginVersion,
    status: state.dashboard.status,
    language: state.dashboard.language,
    de_active_reasons: state.dashboard.de_active_reasons,
    activeSitesCount: state.dashboard.activeSitesCount,
    deactivedSitesCount: state.dashboard.deactivedSitesCount,
    analytics: state.dashboard.analytics,
    accountType: state.auth.signin && state.auth.signin.accountType,
    activeProfile: state.auth.signin && state.auth.signin.activeProfile,
    mailchimpAnalytics: state.dashboard.mailchimpAnalytics,
    totalUserCount: state.dashboard.totalUserCount,
    newUserCount: state.dashboard.newUserCount,
    sitesConfig: state.dashboard.sitesConfig,
    overviewLoader: state.dashboard.overviewLoader,
    dashboardSearchLoader: state.dashboard.dashboardSearchLoader,
    innitialCall: state.dashboard.innitialCall,
  };
};

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default DashboardContainer;
