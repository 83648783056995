export const ACCOUNT_SWITCH = 'ACCOUNT_SWITCH';
export const ACCOUNT_SWITCH_SUCCESS = 'ACCOUNT_SWITCH_SUCCESS';
export const ACCOUNT_SWITCH_FAILED = 'ACCOUNT_SWITCH_FAILED';
export const RESET_SIGIN_REDIRECT = 'RESET_SIGIN_REDIRECT';

export const RESET_ACCOUNT_SWITCH_SUCCESS = 'RESET_ACCOUNT_SWITCH_SUCCESS';

export function switchToAccount(data) {
  return {
    type: ACCOUNT_SWITCH,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}
export function resetSiginRedirect() {
  return {
    type: RESET_SIGIN_REDIRECT,
  };
}

export function resetAccountSwitchSuccess() {
  return {
    type: RESET_ACCOUNT_SWITCH_SUCCESS,
  };
}
