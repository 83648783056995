import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  SAVE_PROFILE,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILED,
  ADD_CARD,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILED,
  SET_DEFAULT_CARD,
  SET_DEFAULT_CARD_SUCCESS,
  SET_DEFAULT_CARD_FAILED,
  REMOVE_CARD,
  REMOVE_CARD_SUCCESS,
  REMOVE_CARD_FAILED,
} from '../actions/ProfileAction';

const initialState = {
  profile: undefined,
  loading: false,
  updating: false,
  addCardLoading: false,
  cardLoading: false,
  addCardUrl: undefined,
  defaultCard: undefined,
  removedCard: undefined,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.response,
        loading: false,
      };
    case GET_PROFILE_FAILED:
      toast.error('Something went wrong!');
      return {
        ...state,
        profile: undefined,
        loading: false,
      };
    case SAVE_PROFILE:
      return {
        ...state,
        updating: true,
      };
    case SAVE_PROFILE_SUCCESS:
      toast.success('Profile updated successfully');
      return {
        ...state,
        profile: action.response,
        updating: false,
      };
    case SAVE_PROFILE_FAILED:
      toast.error(action.error.data.message);
      return {
        ...state,
        updating: false,
      };
    case ADD_CARD:
      return {
        ...state,
        addCardLoading: true,
        addCardUrl: undefined,
      };
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        addCardLoading: false,
        addCardUrl: action.response.url,
      };
    case ADD_CARD_FAILED:
      toast.error('Unable to add new card. ' + action.error.message);
      return {
        ...state,
        addCardLoading: false,
        addCardUrl: undefined,
      };
    case SET_DEFAULT_CARD:
      return {
        ...state,
        cardLoading: true,
        defaultCard: undefined,
      };
    case SET_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        cardLoading: false,
        defaultCard: action.payment_method,
      };
    case SET_DEFAULT_CARD_FAILED:
      toast.error('Unable to set default card. ' + action.error.message);
      return {
        ...state,
        cardLoading: false,
        defaultCard: undefined,
      };
    case REMOVE_CARD:
      return {
        ...state,
        cardLoading: true,
        removedCard: undefined,
      };
    case REMOVE_CARD_SUCCESS:
      return {
        ...state,
        cardLoading: false,
        removedCard: action.payment_method,
      };
    case REMOVE_CARD_FAILED:
      toast.error('Unable to remove card, ' + action.error.message);
      return {
        ...state,
        cardLoading: false,
        removedCard: undefined,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
