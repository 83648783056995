import { all, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PRODUCTS_DATA,
  GET_PRODUCTS_DATA_SUCCESS,
  GET_PRODUCTS_DATA_FAILED,
} from '../actions/ProductAction';
import { defaultApi } from '../utils/axiosApi';

export function* loadProductsData({ params }) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/products`;

  const { response, error } = yield defaultApi(endpoint, 'GET', params, true);
  if (response) {
    yield put({
      type: GET_PRODUCTS_DATA_SUCCESS,
      response,
    });
  }
  if (error) {
    yield put({
      type: GET_PRODUCTS_DATA_FAILED,
      error,
    });
  }
}

export function* loadProducts() {
  yield takeLatest(GET_PRODUCTS_DATA, loadProductsData);
}

export default function* rootSaga() {
  yield all([loadProducts()]);
}
