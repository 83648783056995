import React, { useEffect, useState } from 'react';
import TooltipComponent from '../../utils/TooltipComponent';
import Templately from '../../Markup/assets/img/Templately.svg';

const FreeItem = ({ monthFree }) => (
  <section className="WpdCheckedUpSellSection WpdSectionGap">
    <div className="WpdCheckedUpSell">
      <div className="WpdCheckedUpSellInfo">
        <a
          href="https://templately.com"
          target="_blank"
          className="WpdCheckedUpSellLink"
        ></a>
        <span className="WpdCheckedUpSellIcon">
          <img src={Templately} alt="" />
        </span>
        <span className="WpdCheckedUpSellDetails">
          <h4 className="WpdCheckedUpSellTitle">Templately</h4>
          <span className="WpdCheckedUpSellSubTitle">
            <span>
              Templately PRO {monthFree} {monthFree > 1 ? 'Months' : 'Month'}{' '}
              FREE Subscription
            </span>
          </span>
        </span>
      </div>
      <div className="WpdCheckedUpSellChecker mt-2">Included For Free</div>
      <TooltipComponent tooltipText="Ultimate Free Templates Cloud for WordPress with 4,000+ Templates – Elementor & Gutenberg Supported">
        <span className="WpdCheckedUpSellTooltip">
          <i className="wpd-icon wpd-question"></i>
        </span>
      </TooltipComponent>
    </div>
  </section>
);

export default FreeItem;
