export const LOAD_USERS_LIST = 'LOAD_USERS_LIST';
export const SWITCHED_TO_USER = 'SWITCHED_TO_USER';
export const SWITCHED_TO_USER_SUCCESS = 'SWITCHED_TO_USER_SUCCESS';
export const SWITCHED_TO_USER_FAILED = 'SWITCHED_TO_USER_FAILED';
export const LOAD_USERS_LIST_SUCCESS = 'LOAD_USERS_LIST_SUCCESS';
export const LOAD_USERS_LIST_FAILED = 'LOAD_USERS_LIST_FAILED';

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';

export function getUserList(params) {
  return {
    type: LOAD_USERS_LIST,
    params,
  };
}
export function loadUserListSuccess(data) {
  return {
    type: LOAD_USERS_LIST_SUCCESS,
    data,
  };
}
export function loadUserListFailed(data) {
  return {
    type: LOAD_USERS_LIST_FAILED,
    data,
  };
}

export function switchTo(user_id) {
  return {
    type: SWITCHED_TO_USER,
    user_id,
  };
}

export function switchBack(user_id) {
  return {
    type: SWITCHED_TO_USER,
    user_id,
    switch_back: true,
  };
}

export function getOrders(search = '') {
  return {
    type: GET_ORDERS,
    search,
  };
}
