import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import LicenseItem from './LicenseItem';

const ManageLicence = ({
  getLicenses,
  loading,
  licenses,
  match,
  history,
  setSiteData,
}) => {
  const linkHistory = useHistory();
  const goToPreviousPath = () => {
    linkHistory.goBack();
  };

  useEffect(() => {
    getLicenses(match.params.paymentId);
  }, []);
  return (
    <section className="WpdMyProductSection">
      <div className="WpdSectionTitleWrap">
        <button onClick={goToPreviousPath} className="WpdBackButton">
          <span className="WpdIcon">
            <i className="wpd-icon wpd-angle-left"></i>
          </span>
        </button>
        <h4 className="WpdSectionTitle mr-2">Manage License</h4>
      </div>
      {loading ? (
        <Loader />
      ) : licenses ? (
        <div className="WpdDataTableWrapper">
          <table className="WpdDataTable">
            <thead>
              <tr>
                <th>Item</th>
                <th>Key</th>
                <th>Status</th>
                <th>Activations</th>
                <th>Expiration</th>
                <th>Manage Sites</th>
                <th>Upgrade</th>
              </tr>
            </thead>
            <tbody>
              {licenses.map((license, index) => (
                <LicenseItem
                  {...license}
                  key={index}
                  history={history}
                  setSiteData={setSiteData}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyMessage>No license found</EmptyMessage>
      )}
    </section>
  );
};

export default ManageLicence;
