import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
  GET_COUNTRIES,
  GET_COUNTRIES_FAILED,
  GET_COUNTRIES_SUCCESS,
  GET_STATES,
  GET_STATES_FAILED,
  GET_STATES_SUCCESS,
} from '../actions/AddressActions';

const initialState = {
  loadingCountries: false,
  loadingStates: false,
  countries: undefined,
  states: undefined,
};

export default function addressReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: undefined,
        states: undefined,
        loadingCountries: true,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.response,
        loadingCountries: false,
      };
    case GET_COUNTRIES_FAILED:
      toast.error(
        action.error?.data?.message
          ? action.error.data.message
          : 'Unable to get countries, please try again'
      );
      return {
        ...state,
        countries: undefined,
        states: undefined,
        loadingCountries: false,
      };
    case GET_STATES:
      return {
        ...state,
        states: undefined,
        loadingStates: true,
      };
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.response,
        loadingStates: false,
      };
    case GET_STATES_FAILED:
      toast.error(
        action.error?.data?.message
          ? action.error.data.message
          : 'Unable to get states, please try again'
      );
      return {
        ...state,
        states: undefined,
        loadingStates: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
