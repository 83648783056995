import { connect } from 'react-redux';
import Conversation from '../components/Conversation/Conversation';
import { getConversation, sendReply } from '../actions/ConversationActions';

const mapStateToProps = (state) => {
  return {
    loading: state.conversation.loading,
    conversationDetails: state.conversation.details,
    replying: state.conversation.replying,
    replySuccess: state.conversation.replySuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConversation: (id) => dispatch(getConversation(id)),
    sendReply: (id, data) => dispatch(sendReply(id, data)),
  };
};

const ConversationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Conversation);

export default ConversationsContainer;
