import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
  GET_PRODUCTS_DATA,
  GET_PRODUCTS_DATA_SUCCESS,
  GET_PRODUCTS_DATA_FAILED,
} from '../actions/ProductAction';

const initialState = {
  products: undefined,
  loading: false,
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS_DATA_SUCCESS:
      return {
        ...state,
        products: action.response,
        loading: false,
      };
    case GET_PRODUCTS_DATA_FAILED:
      toast.error('Something went wrong!');
      return {
        ...state,
        products: undefined,
        loading: false,
      };

    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
