export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILED = 'GET_STATES_FAILED';

export function getCountries() {
  return {
    type: GET_COUNTRIES,
  };
}

export function getStates(country) {
  return {
    type: GET_STATES,
    country,
  };
}
