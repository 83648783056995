import React, { useEffect, useState } from 'react';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { ucFirst } from '../../utils/helper';
import Pagination from 'rc-pagination';
import localInfo from 'rc-pagination/es/locale/en_US';
import Profile from '../Profile/Profile';
import Billing from '../Profile/Billing';
import Password from '../Profile/Password';
import Users from './Users';
import Orders from './Orders';

const ShopManager = (props) => {
  const {
    getUserList,
    users,
    switchTo,
    userType,
    history,
    loading,
    switching,
    orders,
    getOrders,
  } = props;
  const [currentTab, setCurrentTab] = useState('users');
  return (
    <>
      <div className="WpdTabWrapper WpdShopManager">
        <div className="WpdTab">
          <ul className="WpdTabNav">
            <li className="WpdTabNavItem">
              <button
                onClick={() => setCurrentTab('users')}
                className={`WpdTabNavItemLink ${
                  currentTab === 'users' && 'Active'
                }`}
              >
                Users
              </button>
            </li>
            <li className="WpdTabNavItem">
              <button
                onClick={() => setCurrentTab('orders')}
                className={`WpdTabNavItemLink ${
                  currentTab === 'orders' && 'Active'
                }`}
              >
                Orders
              </button>
            </li>
          </ul>
        </div>
        {currentTab === 'users' ? (
          <Users
            getUserList={getUserList}
            users={users}
            switchTo={switchTo}
            userType={userType}
            history={history}
            loading={loading}
          />
        ) : currentTab === 'orders' ? (
          <Orders loading={loading} orders={orders} getOrders={getOrders} />
        ) : null}
      </div>
    </>
  );
};

export default ShopManager;
