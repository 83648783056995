import React, { useEffect, useState } from 'react';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { formatDate, ucFirst } from '../../utils/helper';
import Pagination from 'rc-pagination';
import localInfo from 'rc-pagination/es/locale/en_US';

const Payout = ({ date, amount, payout_method, status }) => (
  <tr>
    <td>
      <label>Date:</label>
      <p>{formatDate(date, false, true)}</p>
    </td>
    <td>
      <label>Amount:</label>
      <p>${amount}</p>
    </td>
    <td>
      <label>Payment Method:</label>
      <p>{ucFirst(payout_method)}</p>
    </td>
    <td>
      <label>Status:</label>
      <p>
        <span
          className={`WpdLabelButton ${
            status.toString() === 'paid' ? 'WpdSuccessAlert' : 'WpdWarningAlert'
          }`}
        >
          <span className="WpdButtonInner">
            <span className="WpdText">{ucFirst(status)}</span>
          </span>
        </span>
      </p>
    </td>
  </tr>
);

const Payouts = ({ loading, affiliateInfo, getPayouts, payouts }) => {
  const perPage = 20;
  const [page, setPage] = useState(1);

  useEffect(() => {
    getPayouts({
      affiliate_id: affiliateInfo.id,
      number: perPage,
      offset: 0,
    });
  }, [affiliateInfo]);

  const handlePageChange = function (page) {
    setPage(page);
    getPayouts({
      affiliate_id: affiliateInfo.id,
      number: perPage,
      offset: (page - 1) * perPage,
    });
  };

  return (
    <div className="WpdTabContent">
      <section className="WpdMyStatisticsSection">
        <div className="WpdSectionTitleWrap">
          <h4 className="WpdSectionTitle mr-2">Payouts</h4>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {payouts && payouts.data && payouts.data.length ? (
              <>
                <div className="WpdDataTableWrapper">
                  <table className="WpdDataTable">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payouts.data.map((reference, index) => (
                        <Payout key={index} {...reference} />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="WpdPaginationWrapper">
                  <Pagination
                    defaultCurrent={1}
                    pageSize={perPage}
                    onChange={handlePageChange}
                    total={payouts.total}
                    prevIcon={'<'}
                    nextIcon={'>'}
                    locale={localInfo}
                    current={page}
                  />
                </div>
              </>
            ) : (
              <EmptyMessage>No payouts found</EmptyMessage>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default Payouts;
