import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
  GET_CONVERSATION,
  GET_CONVERSATION_FAILED,
  GET_CONVERSATION_SUCCESS,
  SEND_REPLY,
  SEND_REPLY_FAILED,
  SEND_REPLY_SUCCESS,
} from '../actions/ConversationActions';

const initialState = {
  details: undefined,
  loading: false,
  replying: false,
  replySuccess: false,
};

export default function conversationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONVERSATION:
      return {
        ...state,
        details: undefined,
        replySuccess: false,
        loading: true,
      };
    case GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      };
    case GET_CONVERSATION_FAILED:
      toast.error(
        action.error?.data?.message
          ? action.error.data.message
          : 'Failed to get conversations'
      );
      return {
        ...state,
        details: undefined,
        loading: false,
      };
    case SEND_REPLY:
      return {
        ...state,
        replySuccess: false,
        replying: true,
      };
    case SEND_REPLY_SUCCESS:
      return {
        ...state,
        replySuccess: true,
        replying: false,
      };
    case SEND_REPLY_FAILED:
      toast.error(
        action.error?.data?.message
          ? action.error.data.message
          : 'Failed to get conversations'
      );
      return {
        ...state,
        replySuccess: false,
        replying: false,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
