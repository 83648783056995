import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Markup/assets/img/WpdLogoWithText.svg';
import CartErrorIllustration from '../../Markup/assets/download.svg';
const CommonDownloads = ({ history, loading, downloadProduct }) => {
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    if (!params.get('signature') || !params.get('name')) {
      history.push('/auth/sign-in');
    } else {
      downloadProduct({
        signature: params.get('signature'),
        download_name: params.get('name'),
      });
    }
  }, []);

  return (
    <div className="WpdPageBody WpdLoginBody">
      <div className="WpdLoginHeader">
        <Link to="/user/dashboard" className="WpdSiteLogo">
          <img src={Logo} alt="WpDeveloper" />
        </Link>
      </div>
      <div className="WpdLoginWrapper">
        <div className="WpdLoginContent">
          <div className="WpdAuthContent">
            <h2 className="WpdAuthTitle WpdColorWhite">
              {loading ? 'Downloading...' : 'Download'}
            </h2>
            <p className="WpdAuthText">
              Your download will be started in a few moment, please wait. Also
              you can download by login to dashboard.
            </p>
            <div className="WpdButtonGroup">
              <Link
                to={'/auth/sign-in'}
                className="WpdButton WpdInfoButton WpdFilled"
              >
                <span className="WpdButtonInner">
                  <span className="WpdText">Sign in to dashboard</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="WpdLoginImage">
          <img src={CartErrorIllustration} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default CommonDownloads;
