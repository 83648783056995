import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
  GET_ORDERS,
  GET_ORDERS_FAILED,
  GET_ORDERS_SUCCESS,
  LOAD_USERS_LIST,
  LOAD_USERS_LIST_FAILED,
  LOAD_USERS_LIST_SUCCESS,
  SWITCHED_TO_USER,
  SWITCHED_TO_USER_FAILED,
  SWITCHED_TO_USER_SUCCESS,
} from '../actions/ShopManagerActions';
import { getErrorMessage } from '../utils/helper';

const initialState = {
  users: [],
  error: [],
  loading: false,
  switching: false,
  orders: undefined,
};

export default function shopManagerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USERS_LIST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.data,
      };
    case LOAD_USERS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.data,
      };
    case SWITCHED_TO_USER:
      return {
        ...state,
        switching: true,
      };
    case SWITCHED_TO_USER_FAILED:
      toast.error(getErrorMessage(action.error, 'Unable to switch account'));
      return {
        ...state,
        switching: false,
      };
    case GET_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.response?.sales,
      };
    case GET_ORDERS_FAILED:
      toast.error(getErrorMessage(action.error, 'Unable to get orders'));
      return {
        ...state,
        loading: false,
        orders: undefined,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
