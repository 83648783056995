import React, { useEffect, useState } from 'react';
import TooltipComponent from '../../utils/TooltipComponent';
import {
  handleCopyToClipBoard,
  statusTextColor,
  ucFirst,
} from '../../utils/helper';

const UrlSection = ({ affiliateInfo }) => {
  return (
    <section className="WpdMyAffiliateSection">
      <div className="WpdSectionTitleWrap">
        <h4 className="WpdSectionTitle mr-2">Affiliate URL’s</h4>
      </div>
      <div className="WpdSimpleInfoWrapper">
        <div className="WpdSimpleInfo">
          <label className="WpdSimpleInfoLabel">Account status</label>
          <span>
            <span
              className={`WpdLabelButton ${statusTextColor(
                affiliateInfo.status
              )}`}
            >
              <span className="WpdButtonInner">
                <span className="WpdText">{ucFirst(affiliateInfo.status)}</span>
              </span>
            </span>
          </span>
        </div>
        <div className="WpdSimpleInfo">
          <label className="WpdSimpleInfoLabel">Your affiliate ID is:</label>
          <span>{affiliateInfo.id}</span>
        </div>
        <div className="WpdSimpleInfo">
          <label className="WpdSimpleInfoLabel">Your referral URL is:</label>
          <span className="WpdAffiliateUrl w-100">
            <span id="affiliate-ref-url">{affiliateInfo.referral_url}</span>
            <TooltipComponent tooltipText={'Click to Copy'}>
              <span
                className="WpdAffiliateUrlCopyButton"
                onClick={(e) =>
                  handleCopyToClipBoard(
                    `affiliate-ref-url`,
                    'Affiliate url copied'
                  )
                }
              >
                <i className="wpd-icon wpd-copy"></i>
              </span>
            </TooltipComponent>
          </span>
        </div>
      </div>
    </section>
  );
};

const UrlGenerator = ({ affiliateInfo }) => {
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [campaignName, setCampaignName] = useState('');
  useEffect(() => {
    if (affiliateInfo) {
      const parsedUrl = new URL(affiliateInfo.referral_url);
      setBaseUrl(parsedUrl.protocol + '//' + parsedUrl.hostname);
      setGeneratedUrl(affiliateInfo.referral_url);
    }
  }, [affiliateInfo]);

  useEffect(() => {
    if (campaignName.length) {
      setGeneratedUrl(
        `${baseUrl}?${affiliateInfo.referral_var}=${affiliateInfo.id}&campaign=${campaignName}`
      );
    } else {
      setGeneratedUrl(
        `${baseUrl}?${affiliateInfo.referral_var}=${affiliateInfo.id}`
      );
    }
  }, [baseUrl, campaignName]);

  return (
    <section className="WpdAffiliateSection WpdSectionGap">
      <div className="WpdSectionTitleWrap">
        <h4 className="WpdSectionTitle mr-2">Referral URL Generator</h4>
        <div className="WpdSimpleInfoWrapper mt-2">
          <div className="WpdSimpleInfo">
            <label className="WpdSimpleInfoLabel">
              Enter any URL from this website in the form below to generate a
              referral link!
            </label>
          </div>
        </div>
      </div>
      <form className="WpdFormWrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">Page Url</label>
              <input
                type="text"
                className="form-control"
                placeholder="https://something.com/anything..."
                value={baseUrl}
                onChange={(e) => setBaseUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">
                Campaign Name (optional)
                <small className="d-block my-1">
                  Enter an OptionalCampaign Name to help track performance.
                </small>
              </label>
              <input
                className="form-control"
                placeholder="Your Campaign Name..."
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="WpdFormGroup">
              <label className="WpdFormLabel">
                Generated Referral URL
                <small className="d-block my-1">
                  Share this Url with your audience.
                </small>
              </label>
              <span className="WpdAffiliateUrl">
                <span id="affiliate-generate-url">
                  {encodeURI(generatedUrl)}
                </span>
                <TooltipComponent tooltipText={'Click to Copy'}>
                  <span
                    className="WpdAffiliateUrlCopyButton"
                    onClick={(e) =>
                      handleCopyToClipBoard(
                        `affiliate-generate-url`,
                        'Generated url copied'
                      )
                    }
                  >
                    <i className="wpd-icon wpd-copy"></i>
                  </span>
                </TooltipComponent>
              </span>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

const AffiliateUrl = ({ affiliateInfo }) => {
  return (
    <div className="WpdTabContent">
      <UrlSection affiliateInfo={affiliateInfo} />
      <UrlGenerator affiliateInfo={affiliateInfo} />
    </div>
  );
};

export default AffiliateUrl;
