import React from 'react';
import ProductIcon from '../../utils/ProductIcon';

const SubscriptionItem = ({
  ID,
  amount,
  frequency,
  initial_amount,
  name,
  renewal_date,
  status,
  handleUpdate,
  icon,
  price_name,
}) => {
  return (
    <tr>
      <td className="WpdTableProductInfoColumn">
        <span className="WpdTableProductInfoWrapper">
          <span className="WpdTableProductInfo">
            <span className="WpdTableProductThumbnail">
              <ProductIcon icon={icon} name={name} />
            </span>
            <span className="WpdTableProductDetails">
              <span className="WpdTableProductTitle">{name}</span>
              <span className="WpdTableProductSemiTitle">
                <span>{price_name}</span>
                <span>
                  ${amount} / {frequency}
                </span>
              </span>
            </span>
          </span>
        </span>
      </td>
      <td>
        <label>Status:</label>
        <span
          className={`WpdLabelButton ${
            status === 'active' ? 'WpdSuccessAlert' : 'WpdDangerAlert'
          }`}
        >
          <span className="WpdButtonInner">
            <span style={{ textTransform: 'capitalize' }} className="WpdText">
              {status}
            </span>
          </span>
        </span>
      </td>
      <td>
        <label>Renewal Date:</label>
        <span>{renewal_date}</span>
      </td>
      <td>
        <label>Initial Amount:</label>
        <span>${initial_amount}</span>
      </td>
      <td>
        <span className="WpdActionWrapper">
          {status === 'active' ? (
            <button
              className="WpdSmallButton WpdHoverWarning"
              onClick={(e) => handleUpdate(ID, status)}
            >
              <span className="WpdButtonInner">
                <span className="WpdText">Cancel</span>
              </span>
            </button>
          ) : (
            ''
          )}
        </span>
      </td>
    </tr>
  );
};

export default SubscriptionItem;
