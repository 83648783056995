import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
  GENERATE_INVOICE,
  GENERATE_INVOICE_FAILED,
  GENERATE_INVOICE_SUCCESS,
  GET_INVOICE_DATA,
  GET_INVOICE_DATA_FAILED,
  GET_INVOICE_DATA_SUCCESS,
  RESET_GENERATED,
} from '../actions/InvoiceActions';
import { getErrorMessage } from '../utils/helper';

const initialState = {
  invoiceData: undefined,
  loading: false,
  generated: undefined,
};

export default function generateInvoiceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVOICE_DATA:
      return {
        ...state,
        generated: undefined,
        loading: true,
      };
    case GET_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        invoiceData: action.response,
        loading: false,
      };
    case GET_INVOICE_DATA_FAILED:
      toast.error(getErrorMessage(action.error, 'Failed to get invoice data'));
      return {
        ...state,
        invoiceData: undefined,
        loading: false,
      };
    case GENERATE_INVOICE:
      return {
        ...state,
        generated: undefined,
        loading: true,
      };
    case GENERATE_INVOICE_SUCCESS:
      return {
        ...state,
        generated: action.response,
        loading: false,
      };
    case GENERATE_INVOICE_FAILED:
      action.error.data
        .text()
        .then((r) => JSON.parse(r))
        .then((data) =>
          toast.error(data.message ? data.message : 'Failed to download')
        );
      return {
        ...state,
        generated: undefined,
        loading: false,
      };
    case RESET_GENERATED:
      return {
        ...state,
        generated: undefined,
      };
    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
