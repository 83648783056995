export const GET_PRODUCT_SITE_DATA = 'GET_PRODUCT_SITE_DATA';
export const GET_PRODUCT_SITE_DATA_SUCCESS = 'GET_PRODUCT_SITE_DATA_SUCCESS';
export const GET_PRODUCT_SITE_DATA_FAILED = 'GET_PRODUCT_SITE_DATA_FAILED';

export const MANAGE_SITE = 'MANAGE_SITE';

export const MANAGE_SITE_SUCCESS = 'MANAGE_SITE_SUCCESS';
export const MANAGE_SITE_FAILED = 'MANAGE_SITE_FAILED';

export const SET_SITE_DATA = 'SET_SITE_DATA';

export function manageSite(args) {
  return {
    type: MANAGE_SITE,
    ...args,
  };
}

export function getProductSiteData(id) {
  return {
    type: GET_PRODUCT_SITE_DATA,
    id,
  };
}

export function setSiteData(paymentId, data) {
  return {
    type: SET_SITE_DATA,
    paymentId,
    data,
  };
}
