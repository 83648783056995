import React, { useEffect, useState } from 'react';
import TooltipComponent from '../../utils/TooltipComponent';
import ErrorMessage from '../../utils/ErrorMessage';

const Coupon = ({
  coupon,
  couponCode,
  setCouponCode,
  applyCoupon,
  showCoupon,
  setShowCoupon,
  couponError,
  couponRef,
  handleShowCouponClick,
  couponProcessing,
  renewalDiscount,
  addToCart,
}) => {
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  useEffect(() => {
    if (renewalDiscount) {
      setIsCouponApplied(true);
    } else {
      setIsCouponApplied(false);
    }
  }, [renewalDiscount]);

  useEffect(() => {
    if (coupon && coupon.is_valid) {
      setIsCouponApplied(true);
    } else {
      setIsCouponApplied(false);
    }
  }, [coupon]);

  const getAmount = () => {
    if (renewalDiscount) {
      //return '$' + renewalDiscount;
      return '25% Renewal Discount';
    } else {
      if (coupon) {
        if (coupon.type === 'percent') {
          return (
            <>
              {coupon.code} <small>({coupon.amount}%)</small>
            </>
          );
        } else {
          return (
            <>
              {coupon.code} <small>(${coupon.amount})</small>
            </>
          );
        }
      }
    }
  };
  const handleRenewalCancel = (e) => {
    e.preventDefault();
    addToCart(
      {
        action: 'renew',
        type: 'remove',
      },
      true
    );
  };

  return (
    <section
      className={`WpdLicenseSection WpdSectionGap 
      ${couponProcessing ? 'is-loading' : ''}
      ${showCoupon ? 'Expanded' : ''}
      ${isCouponApplied ? 'Applied' : ''}
    `}
    >
      {!isCouponApplied ? (
        <>
          <h4
            className="WpdLicenseSectionTitle"
            onClick={handleShowCouponClick}
          >
            Have a Discount Code?
            <TooltipComponent tooltipText={'Click to Enter it'}>
              <span className="WpdCollapserToggler">
                <i className="wpd-icon wpd-angle-down"></i>
              </span>
            </TooltipComponent>
          </h4>
          {showCoupon && (
            <div className="WpdLicenseSectionBody">
              <div className="WpdFormGroup">
                <input
                  type="text"
                  ref={couponRef}
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className={`form-control ${
                    couponError && couponError.length && 'WpdHasError'
                  }`}
                  placeholder="Enter Discount Coupon"
                />
                <button
                  onClick={() => applyCoupon(true)}
                  className="WpdApplyCouponButton"
                >
                  <span className="WpdText">Apply</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="WpdLicenseAppliedScreen">
          <div className="WpdText">
            <span className="WpdHighlighted">Discount Applied: </span>
            <span> {getAmount()}</span>
          </div>
          {!renewalDiscount && (
            <button
              className="WpdRemoveButton WpdHoverWarning"
              onClick={() => applyCoupon(false)}
            >
              <span className="WpdRemoveIcon">
                <i className="wpd-icon wpd-cancel" />
              </span>
            </button>
          )}
        </div>
      )}
    </section>
  );
};
export default Coupon;
