import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_DASHBOARD_REPORT,
  RENDER_DASHBOARD_REPORT,
  LOAD_DASHBOARD_REPORT_FAILED,
  LOAD_DASHBOARD_REPORT_SUPER_ADMIN,
  RENDER_DASHBOARD_REPORT_SUPER_ADMIN,
  LOAD_DASHBOARD_REPORT_FAILED_SUPER_ADMIN,
  EXPORT_DATA,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_FAILED,
  LOAD_DASHBOARD_OVERVIEW_REPORT,
  RENDER_DASHBOARD_OVERVIEW_REPORT,
  LOAD_DASHBOARD_OVERVIEW_REPORT_FAILED,
} from '../actions/DashboardActions';

import { TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

import { ACTIVE_COMPANY_NOT_FOUND } from '../actions/SigninActions';

export function* fetchAllDashboardReports(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/reports/dashboard`;

  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );

  if (response) {
    const data = response.data;

    yield put({
      type: RENDER_DASHBOARD_REPORT,
      allProducts: data.data && data.data.allProducts,
      siteList: data.data && data.data.siteList,
      wpVersion: data.data && data.data.wpVersion,
      phpVersion: data.data && data.data.phpVersion,
      pluginVersion: data.data && data.data.pluginVersion,
      status: data.data && data.data.status,
      language: data.data && data.data.language,
      de_active_reasons: data.data && data.data.de_active_reasons,
      activeSitesCount: data.data && data.data.activeSitesCount,
      deactivedSitesCount: data.data && data.data.deactivedSitesCount,
      analytics: data.data && data.data.analytics,
      mailchimpAnalytics: data.data && data.data.mailChimpAnalytics,
      totalUserCount: data.data && data.data.totalUserCount,
      newUserCount: data.data && data.data.newUserCount,
      sitesConfig: data.data && data.data.sitesConfig,
    });
  }
  if (error) {
    const data = error.data;

    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_DASHBOARD_REPORT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchDashboardOverViewReports(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/reports/dashboard/overview`;

  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const data = response.data;
    yield put({
      type: RENDER_DASHBOARD_OVERVIEW_REPORT,
      allProducts: data.data && data.data.allProducts,
      totalUserCount: data.data && data.data.totalUserCount,
    });
  }
  if (error) {
    const data = error.data;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_DASHBOARD_OVERVIEW_REPORT_FAILED,
        message: data.message,
      });
    }
  }
}

export function* fetchAllDashboardReportsForSuperAdmin(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/reports/dashboard`;

  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const data = response.data;
    yield put({
      type: RENDER_DASHBOARD_REPORT_SUPER_ADMIN,
      allProducts: data.data && data.data.allProducts,
      siteList: data.data && data.data.siteList,
      wpVersion: data.data && data.data.wpVersion,
      phpVersion: data.data && data.data.phpVersion,
      pluginVersion: data.data && data.data.pluginVersion,
      status: data.data && data.data.status,
      language: data.data && data.data.language,
      de_active_reasons: data.data && data.data.de_active_reasons,
      activeSitesCount: data.data && data.data.activeSitesCount,
      deactivedSitesCount: data.data && data.data.deactivedSitesCount,
      analytics: data.data && data.data.analytics,
      mailchimpAnalytics: data.data && data.data.mailChimpAnalytics,
      totalUserCount: data.data && data.data.totalUserCount,
      newUserCount: data.data && data.data.newUserCount,
      sitesConfig: data.data && data.data.sitesConfig,
    });
  }
  if (error) {
    const data = error.data;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: LOAD_DASHBOARD_REPORT_FAILED_SUPER_ADMIN,
        message: data.message,
      });
    }
  }
}

export function* exportDashboardReports(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/export-data`;

  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(endpoint, 'POST', credentials2);
  if (response) {
    const data = response.data;
    yield put({
      type: EXPORT_DATA_SUCCESS,
      data: data.data,
    });
  }
  if (error) {
    const data = error.data;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else if (data.status === 'company-not-found') {
      yield put({
        type: ACTIVE_COMPANY_NOT_FOUND,
        message: data.message,
        data: data.data,
      });
    } else if (data.status === 'access-removed') {
      yield put({
        type: ACCESS_REMOVED,
        message: data.message,
        data: data.data,
      });
    } else {
      yield put({
        type: EXPORT_DATA_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadReport() {
  yield takeLatest(LOAD_DASHBOARD_REPORT, fetchAllDashboardReports);
}

export function* loadOverViewReport() {
  yield takeLatest(
    LOAD_DASHBOARD_OVERVIEW_REPORT,
    fetchDashboardOverViewReports
  );
}

export function* exportReportData() {
  yield takeLatest(EXPORT_DATA, exportDashboardReports);
}

export function* loadReportForSuperAdmin() {
  yield takeLatest(
    LOAD_DASHBOARD_REPORT_SUPER_ADMIN,
    fetchAllDashboardReportsForSuperAdmin
  );
}

export default function* rootSaga() {
  yield all([
    loadReport(),
    loadReportForSuperAdmin(),
    exportReportData(),
    loadOverViewReport(),
  ]);
}
