export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_VALIDATION_FAILED = 'SIGN_IN_VALIDATION_FAILED';

export const SET_AUTO_LOGGED_IN_USER = 'SET_AUTO_LOGGED_IN_USER';

export const ACTIVE_COMPANY_NOT_FOUND = 'ACTIVE_COMPANY_NOT_FOUND';

export const REQUIRED_2FA = 'REQUIRED_2FA';
export const REQUIRED_2FA_SIGNED_IN = 'REQUIRED_2FA_SIGNED_IN';

export const VERIFY_2FA = 'VERIFY_2FA';
export const VERIFY_2FA_SUCCESS = 'VERIFY_2FA_SUCCESS';
export const VERIFY_2FA_FAILED = 'VERIFY_2FA_FAILED';

export const SETUP_2FA = 'SETUP_2FA';
export const CONFIGURE_2FA = 'CONFIGURE_2FA';
export const CONFIGURE_2FA_SUCCESS = 'CONFIGURE_2FA_SUCCESS';
export const CONFIGURE_2FA_FAILED = 'CONFIGURE_2FA_FAILED';

export function signin({ username, password }) {
  return {
    type: SIGN_IN,
    credential: {
      username,
      password,
    },
  };
}

export function setAutoLoggedInUser(user) {
  return {
    type: SET_AUTO_LOGGED_IN_USER,
    user,
  };
}
export function verify2fa(user_id, code) {
  return {
    type: VERIFY_2FA,
    user_id,
    code,
  };
}

export function require2faSetup(data) {
  return {
    type: SETUP_2FA,
    data,
  };
}

export function require2fa(data) {
  return {
    type: REQUIRED_2FA_SIGNED_IN,
    data,
  };
}

export function configure2fa(key = '') {
  return {
    type: CONFIGURE_2FA,
    key,
  };
}
