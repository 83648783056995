import React from 'react';

const ProductIcon = ({ icon, name }) => {
  const stringSplit =
    name &&
    name
      .split(' ')
      .filter((e, i) => i < 2)
      .reduce((prev, curr) => prev[0] + curr[0]);
  return icon ? (
    <img src={icon} alt={name} />
  ) : (
    <span className="WpdProductTextIcon">{stringSplit}</span>
  );
};

export default ProductIcon;
