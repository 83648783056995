export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export const PASSWORD_RESET_VALIDATION_FAILED =
  'PASSWORD_RESET_VALIDATION_FAILED';

export function userResetPass(data) {
  return {
    type: PASSWORD_RESET,
    data,
  };
}
