import React from 'react';
import Loader from '../../utils/Loader';
import CheckoutHeader from './CheckoutHeader';

const CheckoutLoader = () => {
  return (
    <>
      <CheckoutHeader />
      <div className="WpdCheckoutContent WpdSectionGap">
        <div className="container-fluid">
          <Loader />
        </div>
      </div>
    </>
  );
};

export default CheckoutLoader;
