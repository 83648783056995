import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from '../utils/axiosApi';
import {
  GET_CONVERSATION,
  GET_CONVERSATION_FAILED,
  GET_CONVERSATION_SUCCESS,
  SEND_REPLY,
  SEND_REPLY_FAILED,
  SEND_REPLY_SUCCESS,
} from '../actions/ConversationActions';

export function* getFullConversation({ id }) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/tickets`;

  const { response, error } = yield defaultApi(
    endpoint,
    'GET',
    {
      conversation_id: id,
    },
    true
  );
  if (response) {
    yield put({
      type: GET_CONVERSATION_SUCCESS,
      response,
    });
  }
  if (error) {
    yield put({
      type: GET_CONVERSATION_FAILED,
      error,
    });
  }
}

export function* conversation() {
  yield takeLatest(GET_CONVERSATION, getFullConversation);
}

export function* sendNewReply({ id, data }) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/tickets`;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    {
      conversation_id: id,
      ...data,
    },
    true
  );
  if (response) {
    yield put({
      type: SEND_REPLY_SUCCESS,
      response,
    });
  }
  if (error) {
    yield put({
      type: SEND_REPLY_FAILED,
      error,
    });
  }
}

export function* reply() {
  yield takeLatest(SEND_REPLY, sendNewReply);
}

export default function* rootSaga() {
  yield all([conversation(), reply()]);
}
