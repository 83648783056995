import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';

const TooltipComponent = ({ tooltipText = 'Click to copy', children }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TooltipComponent;
