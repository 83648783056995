import { connect } from 'react-redux';
import GenerateInvoice from '../components/GenerateInvoice/GenerateInvoice';
import {
  generateCustomInvoice,
  getInvoiceData,
  resetGenerated,
} from '../actions/InvoiceActions';
import { getCountries, getStates } from '../actions/AddressActions';

const mapStateToProps = (state) => {
  return {
    invoiceData: state.generateInvoice.invoiceData,
    loading: state.generateInvoice.loading,
    countries: state.address.countries,
    states: state.address.states,
    loadingCountries: state.address.loadingCountries,
    loadingStates: state.address.loadingStates,
    generated: state.generateInvoice.generated,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInvoiceData: (params) => dispatch(getInvoiceData(params)),
    getCountries: () => dispatch(getCountries()),
    getStates: (country) => dispatch(getStates(country)),
    generateCustomInvoice: (data) => dispatch(generateCustomInvoice(data)),
    resetGenerated: () => dispatch(resetGenerated()),
  };
};

const GenerateInvoiceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateInvoice);

export default GenerateInvoiceContainer;
