import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import routes from '../routes';
import { isProductionMode } from '../utils/helper';

function AuthLayout({ history, user_type, setup2fa }) {
  /* eslint-disable */
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token && !setup2fa) {
      user_type === 'SUPER_ADMIN'
        ? history.push('/user/admin_user/dashboard')
        : history.push('/user/dashboard');
    }
  }, []);
  /* eslint-enable */

  const getRoutes = (routes) => {
    const finalRouts = routes.filter((r) => {
      return r.layout === '/auth';
    });

    let checkPath =
      history &&
      history.location &&
      history.location.pathname &&
      history.location.pathname.split('/');
    let lastRouteParams = '/' + checkPath[2];

    let routePaths = finalRouts.map((r) => r.path);
    if (!routePaths.includes(lastRouteParams)) {
      history.push('/auth/sign-in');
    }

    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Switch>{getRoutes(routes)}</Switch>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.util.loading,
    user_type: state.auth && state.auth.user_type,
    setup2fa: state.auth.setup2fa,
  };
};

export default connect(mapStateToProps)(AuthLayout);
