import React from 'react';
import ProductIcon from '../../../utils/ProductIcon';

const MyDownloadItem = ({
  name,
  icon,
  download_id,
  price_id,
  payment_id,
  download_key,
  token,
  downloadProduct,
  downloadItem,
  downloading,
  download_name,
}) => {
  const handleDownload = (e) => {
    e.preventDefault();
    downloadProduct({
      download_name,
      download_id,
      price_id,
      payment_id,
      download_key,
      token,
    });
  };
  const isDownloading = () =>
    downloading &&
    downloadItem &&
    downloadItem.download_id === download_id &&
    downloadItem.price_id === price_id &&
    downloadItem.download_key === download_key;

  return (
    <div
      className={`WpdDownloadInfoWrapper ${
        isDownloading() ? 'is-loading' : ''
      }`}
    >
      <span className="WpdDownloadInfo">
        <span className="WpdDownloadThumbnail">
          <ProductIcon icon={icon} name={name} />
        </span>
        <span className="WpdDownloadDetails">
          <span className="WpdDownloadTitle">
            {name.trim().replace(/\-$/, '')}
          </span>
        </span>
      </span>
      <button
        className={`WpdSmallButton WpdHoverInfo ml-auto`}
        style={{ minWidth: 110 }}
        onClick={handleDownload}
      >
        <span className="WpdButtonInner">
          <span className="WpdIcon">
            <i className="wpd-icon wpd-download-2" />
          </span>
          <span className="WpdText">
            {isDownloading() ? 'Downloading...' : 'Download'}
          </span>
        </span>
      </button>
    </div>
  );
};

export default MyDownloadItem;
