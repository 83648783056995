import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import checkoutReducer from './CheckoutReducer';
import dashboardReducer from './DashboardReducer';
import manageSiteReducer from './ManageSiteReducer';
import productReducer from './ProductReducer';
import purchaseHistoryReducer from './PurchaseHistoryReducer';
import resetPassReducer from './ResetPasswordReducer';
import sendForgetPassLinkReducer from './SendForgetPassLinkReducer';
import signinReducer from './SigninReducer';
import subscriptionReducer from './SubscriptionReducer';
import utilReducer from './UtilReducer';
import newsReducer from './NewsReducer';
import profileReducer from './ProfileReducer';
import manageLicensesReducer from './ManageLicenseReducer';
import paymentSuccessReducer from './PaymentSuccessReducer';
import upgradeLicenseReducer from './UpgradeLicenseReducer';
import addressReducer from './AddressReducer';
import myTicketsReducer from './MyTicketsReducer';
import downloadsReducer from './DownloadsReducer';
import conversationReducer from './ConversationReducer';
import generateInvoiceReducer from './GenerateInvoiceReducer';
import affiliateReducer from './AffiliateReducer';
import shopManagerReducer from './ShopManagerReducer';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['signin'],
};

const RootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(authPersistConfig, signinReducer),
    sendForgetPassLink: sendForgetPassLinkReducer,
    resetPass: resetPassReducer,
    dashboard: dashboardReducer,
    util: utilReducer,
    subscription: subscriptionReducer,
    checkout: checkoutReducer,
    product: productReducer,
    profile: profileReducer,
    purchaseHistory: purchaseHistoryReducer,
    manageSite: manageSiteReducer,
    news: newsReducer,
    manageLicense: manageLicensesReducer,
    paymentSuccess: paymentSuccessReducer,
    upgradeLicense: upgradeLicenseReducer,
    address: addressReducer,
    myTickets: myTicketsReducer,
    downloads: downloadsReducer,
    conversation: conversationReducer,
    generateInvoice: generateInvoiceReducer,
    affiliate: affiliateReducer,
    shopManager: shopManagerReducer,
  });

export default RootReducer;
