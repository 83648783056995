import { all, put, takeLatest } from 'redux-saga/effects';
import {
  GET_PURCHASE_DATA,
  GET_PURCHASE_DATA_SUCCESS,
  GET_PURCHASE_DATA_FAILED,
} from '../actions/PurchaseHistoryActions';
import { defaultApi } from '../utils/axiosApi';

export function* loadPurchaseData() {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/history`;

  const { response, error } = yield defaultApi(
    endpoint,
    'GET',
    undefined,
    true
  );
  if (response) {
    yield put({
      type: GET_PURCHASE_DATA_SUCCESS,
      response,
    });
  }
  if (error) {
    yield put({
      type: GET_PURCHASE_DATA_FAILED,
      error,
    });
  }
}

export function* loadpurchase() {
  yield takeLatest(GET_PURCHASE_DATA, loadPurchaseData);
}

export default function* rootSaga() {
  yield all([loadpurchase()]);
}
