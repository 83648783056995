import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../utils/helper';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { Modal } from 'react-bootstrap';
import ImageUploader from '../../utils/FileUploader/ImageUploader';
import ErrorMessage from '../../utils/ErrorMessage';

const Conversation = ({
  match,
  loading,
  getConversation,
  conversationDetails,
  sendReply,
  replying,
  replySuccess,
}) => {
  const linkHistory = useHistory();
  const goToPreviousPath = () => {
    linkHistory.goBack();
  };

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    message: '',
    message_attachment: [],
  });
  const [messageError, setMessageError] = useState(undefined);

  useEffect(() => {
    match.params.id && getConversation(match.params.id);
  }, []);

  useEffect(() => {
    if (replySuccess) {
      getConversation(match.params.id);
      setFormData({
        message: '',
        message_attachment: [],
      });
      setShowModal(false);
    }
  }, [replySuccess]);

  const handleReply = (e) => {
    e.preventDefault();
    if (!formData.message.length) {
      setMessageError('Message is required');
      return;
    }
    setMessageError('');
    sendReply(match.params.id, formData);
  };

  return (
    <>
      <section className="WpdMyProductSection">
        <div className="WpdSectionTitleWrap">
          <button onClick={goToPreviousPath} className="WpdBackButton">
            <span className="WpdIcon">
              <i className="wpd-icon wpd-angle-left"></i>
            </span>
          </button>
          <h4 className="WpdSectionTitle mr-2">Threads with WPDeveloper</h4>
          <button
            className="WpdButton WpdInfoButton WpdFilled ml-auto"
            data-toggle="modal"
            data-target="#exampleModalCenter"
            onClick={(e) => setShowModal(true)}
          >
            <span className="WpdButtonInner">
              <span className="WpdText">New Reply</span>
              <span className="WpdIcon">
                <i className="wpd-icon wpd-plus"></i>
              </span>
            </span>
          </button>
        </div>
        {conversationDetails && (
          <div className="WpdSectionTitleWrap flex-column align-items-start">
            <h4 className="WpdSectionSemiTitle">
              <b>Subject:</b> {conversationDetails.conversation.subject}
            </h4>
            <p className="WpdColorSecondary">
              Started on{' '}
              {formatDate(conversationDetails.conversation.createdAt)}
            </p>
          </div>
        )}
      </section>
      {loading ? (
        <Loader />
      ) : conversationDetails &&
        conversationDetails.threads &&
        Array.isArray(conversationDetails.threads) ? (
        <section className="WpdConversationSection">
          <div className="WpdConversationWrapper">
            <div className="WpdConversationGroup">
              {conversationDetails.threads.map((thread, index) => {
                return thread.body && thread.body.length ? (
                  <div
                    className={
                      thread.source.via.toString() === 'user'
                        ? `WpdSupportConversation`
                        : `WpdSelfConversation`
                    }
                    key={index}
                  >
                    <>
                      <div className="WpdConversationAuthorImage">
                        <img
                          src={thread.createdBy.photoUrl}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </div>
                      <div className="WpdConversationMessageGroup">
                        <div className="WpdConversationMessage">
                          <div
                            dangerouslySetInnerHTML={{ __html: thread.body }}
                          />
                          {(thread._embedded.attachments &&
                            thread._embedded.attachments.length && (
                              <div className="WpdConversationAttachmentGroup">
                                {thread._embedded.attachments.map(
                                  (attachment, key) => (
                                    <a
                                      href={attachment._links.web.href}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="WpdConversationAttachment"
                                      key={key}
                                    >
                                      <i className="wpd-icon wpd-download"></i>
                                      <span>
                                        {attachment.filename.length > 20
                                          ? `${attachment.filename.substr(
                                              0,
                                              9
                                            )}...${attachment.filename.substr(
                                              attachment.filename.length - 7
                                            )}`
                                          : attachment.filename}
                                      </span>
                                    </a>
                                  )
                                )}
                              </div>
                            )) ||
                            ''}
                        </div>
                        <p className="WpdConversationTime">
                          {formatDate(thread.createdAt, false, true)}
                        </p>
                      </div>
                    </>
                  </div>
                ) : thread.status && thread.status.toString() === 'closed' ? (
                  <div className="alert alert-secondary py-1 px-3 w-100">
                    <small>
                      Thread is marked as closed by{' '}
                      <strong>{`${thread.createdBy?.first} ${thread.createdBy?.last}`}</strong>{' '}
                      in{' '}
                      <strong>
                        {formatDate(thread.createdAt, false, true)}
                      </strong>
                    </small>
                  </div>
                ) : (
                  ''
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        <EmptyMessage>No threads found</EmptyMessage>
      )}
      <Modal
        show={showModal}
        onHide={(e) => setShowModal(false)}
        centered
        size="lg"
        className={replying ? 'is-loading-modal' : ''}
        backdrop={replying ? 'static' : true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send new reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`WpdFormWrapper`}>
            <div className="row">
              <div className="col-md-12">
                <div className="WpdFormGroup">
                  <label className="WpdFormLabel">Message</label>
                  <textarea
                    rows="5"
                    className={`form-control ${messageError && 'WpdHasError'}`}
                    placeholder="Example: Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  ></textarea>
                  <ErrorMessage error={messageError} />
                </div>
              </div>
              <div className="col-md-12 mb-0">
                <div className="WpdFileBrowseWrapper">
                  <label className="WpdFormLabel mr-3">Add Attachment:</label>
                  <ImageUploader
                    onFileChange={(files) =>
                      setFormData({ ...formData, message_attachment: files })
                    }
                    multiple={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="WpdButton WpdWarningButton ml-auto"
            data-dismiss="modal"
            onClick={(e) => setShowModal(false)}
          >
            <span className="WpdButtonInner">
              <span className="WpdText">Cancel</span>
            </span>
          </button>
          <button
            className="WpdButton WpdInfoButton WpdFilled ml-2"
            onClick={handleReply}
          >
            <span className="WpdButtonInner">
              <span className="WpdText">Submit</span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Conversation;
