import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from '../utils/axiosApi';
import {
  GET_UPGRADES,
  GET_UPGRADES_FAILED,
  GET_UPGRADES_SUCCESS,
} from '../actions/UpgradeLicenseActions';

export function* getUpgrades({ licenseId }) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/upgrades`;

  const { response, error } = yield defaultApi(
    endpoint,
    'GET',
    {
      license_id: licenseId,
    },
    true
  );
  if (response) {
    yield put({
      type: GET_UPGRADES_SUCCESS,
      response,
    });
  }
  if (error) {
    yield put({
      type: GET_UPGRADES_FAILED,
      error,
    });
  }
}

export function* upgrades() {
  yield takeLatest(GET_UPGRADES, getUpgrades);
}

export default function* rootSaga() {
  yield all([upgrades()]);
}
