import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FileIcon from './file.jpeg';

const ImageUploader = ({
  currentImages,
  onFileChange,
  multiple,
  uploaded,
  model,
  fileName,
  url,
  uploadImages,
  removeImages,
  onDeleteCurrentImage,
}) => {
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentFiles, setCurrentFiles] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const [hideCurrentImage, setHideCurrentImage] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [currentImagesPreview, setCurrentImagesPreview] = useState([]);
  let formData = new FormData();

  useEffect(() => {
    setCurrentFiles(currentImages);
  }, [currentImages]);

  useEffect(() => {
    if (!uploaded) return;
    if (uploaded[`${model}.${fileName}`] === undefined) return;
    if (!multiple) {
      onFileChange(uploaded[`${model}.${fileName}`]);
    } else {
      let uploadedFiles = uploaded[`${model}.${fileName}`]
        ? uploaded[`${model}.${fileName}`]
        : [];
      onFileChange([...uploadedFiles]);
    }
  }, [uploaded]);

  useEffect(() => {
    if (files) {
      setPreviews(filePreviewer(files));
    } else {
      setPreviews([]);
    }
  }, [files]);

  useEffect(() => {
    if (currentFiles) {
      setCurrentImagesPreview(getCurrentImagesPreview(currentFiles));
    } else {
      setCurrentImagesPreview([]);
    }
  }, [currentFiles]);

  const handleFileUpload = (e) => {
    const newFiles = e.target.files;
    if (newFiles.length) {
      if (url) {
        if (!multiple) {
          formData.append(fileName, newFiles && newFiles[0]);
        } else {
          Array.prototype.forEach.call(newFiles, function (file, index) {
            formData.append(`screenshots[]`, file);
          });
        }
        uploadImages(url, fileName, formData);
      } else {
        if (!multiple) {
          setFiles([...newFiles]);
          onFileChange([...newFiles]);
          setHideCurrentImage(true);
        } else {
          setFiles([...files, ...newFiles]);
          onFileChange([...files, ...newFiles]);
        }
      }
    }
  };

  const handlePreview = (e, index, type = 'new') => {
    e.preventDefault();
    if (type === 'current') {
      setImagePreview(currentFiles[index]);
    } else {
      setImagePreview(URL.createObjectURL(files[index]));
    }
    setShowModal(true);
  };

  const filePreviewer = (files) => {
    const previews = [];
    for (let i = 0; i < files.length; i++) {
      const isImage = files[i].type.includes('image') ?? false;
      let previewUrl = '';
      if (isImage) {
        previewUrl = URL.createObjectURL(files[i]);
      }

      previews.push(
        <label
          htmlFor={`preview-image-${i}`}
          key={`preview-image-${i}`}
          className="WpdFileChooser"
        >
          <input type="radio" id={`preview-image-${i}`} name="image-selector" />
          {isImage ? (
            <span style={{ backgroundImage: `url("${previewUrl}")` }}>
              <button
                className="WpdDeleteButton"
                onClick={(e) => handleDelete(e, i)}
              >
                <span>
                  <i className="wpd-icon wpd-cancel"></i>
                </span>
              </button>
              <button
                className="WpdViewButton"
                onClick={(e) => handlePreview(e, i)}
              >
                <span>
                  <i className="wpd-icon wpd-eye"></i>
                </span>
              </button>
            </span>
          ) : (
            <span className="WpdFilePreview">
              <img src={FileIcon} alt="icon" />
              <button
                className="WpdDeleteButton"
                onClick={(e) => handleDelete(e, i)}
              >
                <span>
                  <i className="wpd-icon wpd-cancel"></i>
                </span>
              </button>
              <button
                className="WpdViewButton"
                onClick={(e) => handlePreview(e, i)}
              >
                <span>
                  <i className="wpd-icon wpd-eye"></i>
                </span>
              </button>
            </span>
          )}
        </label>
      );
    }
    return previews;
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDelete = (e, index) => {
    e.preventDefault();
    if (url) {
      if (!multiple) {
        removeImages(url, fileName);
      } else {
        removeImages(url, fileName);
      }
    } else {
      const filesArray = files.filter((each, i) => i !== index);
      setFiles(filesArray);
      onFileChange(filesArray);
    }
  };

  const handleCurrentImageDelete = (e, i) => {
    e.preventDefault();
    const deleted = currentFiles[i];

    if (url) {
      if (!multiple) {
        removeImages(url, {});
      } else {
        removeImages(url, { url: deleted, index: i });
      }
    } else {
      setHideCurrentImage(true);
      if (multiple) {
        currentFiles[i] = null;
        setCurrentFiles(currentFiles);
      }
      onDeleteCurrentImage(deleted);
    }
  };

  const getCurrentImagesPreview = (images) => {
    const previews = [];
    if (typeof images === 'string') images = [images];

    for (let i = 0; i < images.length; i++) {
      images[i] &&
        previews.push(
          <label
            htmlFor={`image-${i}`}
            key={i}
            className={`WpdFileChooser ${
              !multiple && hideCurrentImage && 'hide-current'
            }`}
          >
            <input
              type="radio"
              id={`image-${Math.random().toString(36).substr(2, 5) + i}`}
              name="image-selector"
            />
            <span style={{ backgroundImage: `url("${images[i]}")` }}>
              <button
                className="WpdDeleteButton"
                onClick={(e) => handleCurrentImageDelete(e, i)}
              >
                <span>
                  <i className="wpd-icon wpd-cancel"></i>
                </span>
              </button>
              <button
                className="WpdViewButton"
                onClick={(e) => handlePreview(e, i, 'current')}
              >
                <span>
                  <i className="wpd-icon wpd-eye"></i>
                </span>
              </button>
            </span>
          </label>
        );
    }
    return previews;
  };

  return (
    <div className="WpdFileChooserGroup">
      {currentImagesPreview}
      {previews}
      <label className="WpdFileBrowseButton">
        <span className="d-flex align-items-center">
          <i className="wpd-icon wpd-plus"></i>
        </span>
        <input
          type="file"
          onChange={(e) => handleFileUpload(e)}
          multiple={multiple}
        />
      </label>
      <Modal
        show={showModal}
        onHide={handleClose}
        className={'image-preview-modal'}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imagePreview && (
            <img src={imagePreview} className="img-fluid" alt="basic" />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageUploader;
